import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { AliCloudResponse, appCode, prefix_url } from '@app/common/AliCloudResponse'

enum Title {
  ty = '太原学院',
  tm = '同煤大厦',
  xsj = '新世纪家园别墅区',
  bjjr = '北京假日阳光别墅区',
}

@Injectable({
  providedIn: 'root',
})
export class TemperatureChartService {
  constructor(private http: HttpClient) {}
  async getData(): Promise<any> {
    const url = `${prefix_url}/heating/bgsc/03/`
    const params = new HttpParams().set('appCode', appCode).set('is_all', '1')
    const items = {}
    await this.http
      .get<AliCloudResponse>(url, { params })
      .toPromise()
      .then(res => {
        const data: Array<{
          project: string
          EnvTemperature: number
          biztime: string
        }> = res.data
        data.filter(item => {
          if (!items[item.project]) {
            const date = [item.biztime],
              temperature = [item.EnvTemperature]
            items[item.project] = {
              date,
              temperature,
            }
          } else {
            items[item.project].date.push(item.biztime)
            items[item.project].temperature.push(item.EnvTemperature)
          }
        })
      })
    return Promise.resolve(items)
  }
  getOptions(data) {
    const titles = []
    for (let key in data) {
      titles.push(key)
    }
    let legend_color = ['#287f4e', '#13a9bd', '#f05f44', '#d47336']
    // 调整 echarts legends 的位置
    let legends = titles.map((item, index) => {
      let x = index % 2 === 0 ? '10%' : '50%'
      let y = index > 1 ? '20' : '0'
      return {
        orient: 'horizontal',
        align: 'left',
        icon: 'rect',
        x: x,
        y: y,
        data: [item],
        textStyle: {
          color: `#fff`,
          fontSize: 16,
        },
      }
    })
    // 根据后台返回的数据格式，转换为 echarts 要求的数据格式
    let series = titles.map((item, index) => {
      return {
        name: item,
        type: 'line',
        symbol: 'circle',
        color: `${legend_color[index]}`,
        data: data[item].temperature,
        itemStyle: {},
      }
    })
    return {
      tooltip: {
        trigger: 'axis',
      },
      legend: legends,
      grid: {
        bottom: 30,
      },
      xAxis: {
        show: false,
        axisLabel: {
          interval: 0,
          textStyle: {
            color: '#13a9bd',
            fontStyle: 'normal',
            fontFamily: '微软雅黑',
            fontSize: 16,
          },
        },
        axisTick: {
          show: false,
          lineStyle: {
            color: '#808c96',
          },
        },
        axisLine: {
          lineStyle: {
            color: '#808c96',
            type: 'dotted',
          },
        },
        type: 'category',
        boundaryGap: false,
        data: data[titles[0]].date,
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false,
        },
        axisLabel: {
          margin: 15,
          color: '#13a9bd',
          fontSize: 20,
        },
        axisTick: {
          show: false,
          lineStyle: {
            color: '#ffffff',
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ffffff',
            type: 'dotted',
          },
        },
      },
      series: series,
    }
  }
}
