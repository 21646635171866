<div class="container-wrapper">
  <div class="bg-container"></div>
  <div class="content-wrapper">
    <div class="search-wrapper">
      <form nz-form [nzLayout]="'inline'" [formGroup]="search">
        <nz-form-item>
          <nz-form-label nzFor="sid">查询ID</nz-form-label>
          <nz-form-control>
            <input class="large-width" nz-input formControlName="sid" id="sid" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzFor="premisesId">楼宇</nz-form-label>
          <nz-form-control>
            <nz-select
              formControlName="premisesId"
              class="middle-width"
              nzAllowClear
              id="premisesId"
              (ngModelChange)="handleOnSearchPremiseSelectChange($event)"
            >
              <nz-option
                *ngFor="let item of premises"
                [nzValue]="item.premisesId"
                [nzLabel]="item.premisesName"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzFor="floorId">楼层</nz-form-label>
          <nz-form-control>
            <nz-select
              formControlName="floorId"
              class="middle-width"
              id="floorId"
              nzAllowClear
              (ngModelChange)="handleOnSearchFloorSelectChange($event)"
            >
              <nz-option
                *ngFor="let item of floors"
                [nzValue]="item.floorId"
                [nzLabel]="item.floorName"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control class="operate">
            <button class="rhthermo-button-hover" nz-button (click)="handleOnSearch()">
              搜索
            </button>
            <button class="rhthermo-button-hover" type="reset" nz-button (click)="handleOnReset()">
              重置
            </button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
    <div class="table-wrapper">
      <nz-table #fixedTable [nzData]="data" [nzFrontPagination]="false" nzTableLayout="fixed">
        <thead (nzSortChange)="handleOnSortChange($event)">
          <tr>
            <th
              *ngFor="let column of columns"
              nzAlign="center"
              [nzWidth]="column.width"
              [nzEllipsis]="column.ellipsis ? true : false"
            >
              <span>{{ column.title }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of fixedTable.data">
            <td nzAlign="center" nzEllipsis nz-tooltip [nzTooltipTitle]="data.sid">
              {{ data.sid }}
            </td>
            <td nzAlign="center">{{ data.premisesName }}</td>
            <td nzAlign="center">{{ data.floorName }}</td>
            <td nzAlign="center">{{ data.position }}</td>

            <td nzAlign="center" nzEllipsis nz-tooltip [nzTooltipTitle]="data.tunnelTime">
              {{ data.prevTime }}
            </td>
            <td nzAlign="center">{{ data.angel }}</td>
            <td nzAlign="center">{{ data.account }}</td>
            <td nzAlign="center">{{ data.temperature }}</td>
            <td nzAlign="center">已连接</td>
          </tr>
        </tbody>
      </nz-table>
      <nz-pagination
        [(nzPageIndex)]="pagination.pageNum"
        [nzTotal]="totalCount"
        [nzShowQuickJumper]="true"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[10, 20, 30, 40, 50]"
        [(nzPageSize)]="pagination.pageSize"
        (nzPageIndexChange)="handleOnPageIndexChange($event)"
        (nzPageSizeChange)="handleOnPageSizeChange($event)"
      ></nz-pagination>
    </div>
  </div>
</div>
