import { Component, OnInit } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { RegionalEnergyConsumptionService } from '@service/data-report/regional-energy-consumption.service'
interface response {
  apiLog: any
  data: Array<any> | any
  errCode: number
  errMsg: string
  requestId: string
}
@Component({
  selector: 'app-regional-energy-consumption',
  templateUrl: './regional-energy-consumption.component.html',
  styleUrls: ['./regional-energy-consumption.component.scss'],
})
export class RegionalEnergyConsumptionComponent implements OnInit {
  title: string = '各区域的能耗排名'
  column = [
    {
      title: '区域',
    },
    {
      title: '耗电量(kw/h)',
    },
    {
      title: '耗水量(m³)',
    },
  ]
  data = []
  constructor(private service: RegionalEnergyConsumptionService) {}
  ngOnInit(): void {
    this.service.getData().subscribe(res => {
      this.data = res.data
    })
  }
}
