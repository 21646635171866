/**
 * @apiLog 后台日志
 * @errMsg 错误信息
 * @requestId 请求id
 * @errCode 错误码
 * @data 数据
 */
interface AliCloudResponse {
  apiLog: any
  errMsg: string
  requestId: string
  errCode: 0
  data: any
}

interface UnifyResponse {
  apiLog: string | null
  data: any
  errCode: number
  errMsg: string
  requestId: string
}

const prefix_url = `https://c3e35869d12944cbba6b8ba19f5b2182-cn-beijing.alicloudapi.com`

const appCode = '3cbf3469059b491a91c7f2a43889d381'

const TEMP_HOST = 'http://47.93.62.219:9000'

export { AliCloudResponse, UnifyResponse, prefix_url, appCode, TEMP_HOST }
