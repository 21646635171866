import { NgModule } from '@angular/core'
import { TaiyuanCollegeBuildComponent } from '@components/taiyuan-college-build/taiyuan-college-build.component'
import {
  NzButtonModule,
  NzDividerModule,
  NzFormModule,
  NzInputModule,
  NzInputNumberModule,
  NzModalModule,
  NzPopconfirmModule,
  NzSelectModule,
  NzSwitchModule,
  NzTableModule,
} from 'ng-zorro-antd'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'

@NgModule({
  declarations: [TaiyuanCollegeBuildComponent],
  imports: [
    NzSelectModule,
    FormsModule,
    NzTableModule,
    NzPopconfirmModule,
    CommonModule,
    NzButtonModule,
    NzModalModule,
    NzInputModule,
    NzInputNumberModule,
    NzFormModule,
    ReactiveFormsModule,
    NzDividerModule,
    NzSwitchModule,
  ],
  exports: [TaiyuanCollegeBuildComponent],
})
export class TaiyuanCollegeBuildModule {}
