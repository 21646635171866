import { Component, OnInit } from '@angular/core'
import { AreaStateService } from '@service/data-report/area-state.service'
import { NzTabComponent } from 'ng-zorro-antd'

interface DataStruct {
  name: string
  item: any[]
  indicators: Indicators
  temperature: Temperature
}

interface Indicators {
  left: Array<any> | any
  right: Array<any> | any
}

interface Temperature {
  date: string[]
  external: string[] | number[]
  internal: string[] | number[]
}

interface tabsParam {
  index: number
  tab: NzTabComponent
}

@Component({
  selector: 'app-area-state',
  templateUrl: './area-state.component.html',
  styleUrls: ['./area-state.component.scss'],
})
export class AreaStateComponent implements OnInit {
  constructor(private areaStateService: AreaStateService) {}

  title: string = '项目供热状态'
  temperatureTitle: string = '室内外温度近七日变化'
  /**
   * 近七日温度 echarts options
   */
  temperatureOptions: object = {}
  /**
   * 项目展示 echarts options
   */
  areaRegionalOptions: object = {}
  /**
   * 项目所有数据
   */
  data: any[] = []
  /**
   * 当前选中的项目数据
   */
  currentData: DataStruct = {
    name: '',
    item: [],
    indicators: {
      left: [],
      //@ts-ignore
      rigth: [],
    },
    temperature: null,
  }

  titles = [
    {
      name: '太原学院',
      loading: false,
    },
    {
      name: '同煤大厦',
      loading: false,
    },
    {
      name: '新世纪家园别墅区',
      loading: false,
    },
    {
      name: '北京假日阳光别墅区',
      loading: false,
    },
  ]

  lock: boolean = false

  ngOnInit(): void {
    this.getData(this.titles[0].name)
  }

  getData(projectName) {
    this.areaStateService.getServiceData(projectName).then(res => {
      this.data.push(res)
      this.currentData = res
      this.areaRegionalOptions = this.areaStateService.getMouseMoveChartOptions(res)
      this.getTemperatureChartOptions()
      this.titles.forEach(item => {
        if (item.name === projectName) {
          item.loading = true
        }
      })
    })
  }

  reloadData(name) {
    const result = this.data.filter(item => {
      if (item.name === name) {
        return item
      }
    })
    this.currentData = result[0]
    this.areaRegionalOptions = this.areaStateService.getMouseMoveChartOptions(result)
    this.getTemperatureChartOptions()
  }

  getTemperatureChartOptions() {
    this.temperatureOptions = this.areaStateService.getChartOption(
      this.currentData.temperature.date,
      this.currentData.temperature.external,
      this.currentData.temperature.internal,
    )
  }

  handleChartClick(event) {
    const status = event.data.tooltip.show
    if (status === false || this.lock === true) {
      return
    }
    this.lock = true
    const index = event.seriesIndex
    const options = this.areaStateService.getOptions(this.currentData, index)
    this.areaRegionalOptions = options
  }
  handleChartMouseOut(event) {
    if (!this.lock) {
      return
    }
    this.lock = false
    this.areaRegionalOptions = this.areaStateService.getMouseMoveChartOptions(this.currentData)
  }

  handleTabsSelectChange(param: tabsParam) {
    console.log('tab click param: ', param)
    const res = this.titles[param.index]
    if (res.loading === false) {
      this.getData(this.titles[param.index].name)
    } else {
      this.reloadData(res.name)
    }
  }
}
