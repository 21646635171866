import { NgModule } from '@angular/core'
import { TemperaturePipe } from '@app/pipes/temperature.pipe'
import { FormatTimePipe } from '@app/pipes/format-time.pipe'

@NgModule({
  imports: [],
  declarations: [TemperaturePipe, FormatTimePipe],
  exports: [TemperaturePipe, FormatTimePipe],
})
export class PipeModule {}
