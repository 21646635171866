import { NgModule } from '@angular/core'
import { TaiyuanCollegeSystemComponent } from '@components/taiyuan-college-system/taiyuan-college-system.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import {
  NzButtonModule,
  NzFormModule,
  NzInputModule,
  NzInputNumberModule,
  NzSelectModule,
} from 'ng-zorro-antd'
import { CommonModule } from '@angular/common'

@NgModule({
  declarations: [TaiyuanCollegeSystemComponent],
  imports: [
    FormsModule,
    NzInputNumberModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    ReactiveFormsModule,
    NzFormModule,
    NzInputModule,
  ],
  exports: [TaiyuanCollegeSystemComponent],
})
export class TaiyuanCollegeSystemModule {}
