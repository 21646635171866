import { Component, OnInit, SecurityContext } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { SideFrameService } from '@service/data-report/side-frame.service'

@Component({
  selector: 'app-side-frame',
  templateUrl: './side-frame.component.html',
  styleUrls: ['./side-frame.component.scss'],
})
export class SideFrameComponent implements OnInit {
  path: SafeResourceUrl = ''
  constructor(
    private router: Router,
    private activeRouter: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private sideFrameService: SideFrameService,
  ) {}

  ngOnInit(): void {
    this.activeRouter.queryParams.subscribe(params => {
      // 如果传递的url为空或者未传递，则跳转到首页
      console.log('url params: ', params)
      if (params.url !== '' && params.url !== undefined && params.url !== null) {
        this.path = this.sanitizer.bypassSecurityTrustResourceUrl(params.url)
        this.sideFrameService.delete_content(params.url)
      } else {
        this.router.navigate(['/'])
      }
    })
    window.alert = msg => {
      console.log('rewrite window alert: ', msg)
    }
  }
}
