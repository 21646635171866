import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { TaiyuanCollegeTerminalService } from '@components/taiyuan-college-terminal/taiyuan-college-terminal.service'
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { CommonService } from '@app/common/CommonService'
import { NzModalService } from 'ng-zorro-antd'
import { convertRangeTemperature, convertTemperature } from '@app/common/CommonUtil'

@Component({
  selector: 'app-taiyuan-college-terminal',
  templateUrl: './taiyuan-college-terminal.component.html',
  styleUrls: ['./taiyuan-college-terminal.component.scss'],
})
export class TaiyuanCollegeTerminalComponent implements OnInit {
  columns = []
  data = []
  premises = []
  floors = []
  search = {
    premisesId: '',
    floorId: '',
  }
  modes: Array<any> = []
  types: Array<any> = []
  terminalIds: Array<any> = []

  modalTitle: String = '修改'
  modalVisible: boolean = false
  typeGroupVisible: boolean = false
  validateForm: FormGroup = this.fb.group({
    premisesId: [{ value: null, disabled: true }, [Validators.required]],
    floorId: [{ value: null, disabled: true }, [Validators.required]],
    terminalId: [null, [Validators.required]],
    terminalPosition: [null, [Validators.required]],
    modeGroup: this.fb.group({
      modeName: ['', [Validators.required]],
      typeGroup: this.fb.group({
        typeId: [null],
        temperatureUplimit: [{ value: undefined, disabled: false }],
        temperatureDownlimit: [{ value: undefined, disabled: false }],
      }),
    }),
  })

  constructor(
    private service: TaiyuanCollegeTerminalService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private cdf: ChangeDetectorRef,
    private modal: NzModalService,
  ) {}

  ngOnInit(): void {
    this.columns = this.service.getColumns()
    this.handleInitSearch()
    this.handleGetTerminalId()
    this.handleWatchModeChange()
  }

  handleGetData() {
    const param = {
      pid: this.search.premisesId,
      fid: this.search.floorId,
    }
    this.service.getData(param).then(res => {
      this.data = res
    })
  }
  handleOnDelete() {}

  handleInitSearch() {
    this.handleGetPremises().then(() => {
      this.handleGetFloors()
    })
  }

  handleGetPremises() {
    return new Promise((resolve, reject) => {
      this.commonService.handleGetPremises().then(res => {
        this.premises = res.data.rows
        this.search.premisesId = this.premises[0].premisesId || null
        return resolve()
      })
    })
  }

  handleOnPremiseSelectChange(event) {
    this.search.premisesId = event
    this.floors = []
    this.search.floorId = null
    this.handleGetFloors()
  }

  handleOnSwitch(data) {
    const title = data.disabled ? '关停' : '开启'
    const content = data.disabled ? '确定要关闭吗？' : '确定重新开启此楼吗？'
    const isDisabled = data.disabled ? '1' : '0'
    this.modal.confirm({
      nzTitle: `<i>${title}</i>`,
      nzContent: `${content}`,
      nzOnOk: () => {
        this.service
          .handleSwitch({ pid: this.search.premisesId, fid: data.floorId, isDisabled: isDisabled })
          .then(() => this.handleGetData())
      },
    })
  }

  handleGetFloors() {
    if (this.search.premisesId) {
      this.commonService.handleGetFloors(parseInt(this.search.premisesId)).then(res => {
        this.floors = res.data
        if (this.floors.length > 0) {
          this.search.floorId = this.floors[0].floorId
          this.handleGetModes()
          this.handleGetTypes()
          this.handleGetData()
        }
      })
    }
  }

  handleOnCloseModal() {
    this.modalVisible = false
    if (this.validateForm) {
      this.validateForm.reset()
    }
  }

  handleOnUpdate() {
    this.validateForm.updateValueAndValidity()
    console.log('validate form: ', this.validateForm.getRawValue())
    console.log('valid: ', this.validateForm.valid)
  }

  handleOnFloorsSelectChange($event: any) {}

  // 获取添加时的模式
  handleGetModes() {
    this.service.getModes(this.search).then(res => {
      this.modes = res
    })
  }
  // 获取添加时的温控类型
  handleGetTypes() {
    this.service.getTypes(this.search).then(res => {
      this.types = res
    })
  }
  // 获取可添加的温控 ID
  handleGetTerminalId() {
    this.service.getTerminalId(this.search).then(res => {
      this.terminalIds = res
    })
  }
  handleGetModeName() {
    let modeGroup = this.validateForm.get('modeGroup') as FormGroup
    return modeGroup.get('modeName').value
  }
  handleWatchModeChange() {
    let modeGroup = this.validateForm.get('modeGroup') as FormGroup
    modeGroup.get('modeName').valueChanges.subscribe(val => {
      this.typeGroupVisible = false
      let typeGroup: FormGroup
      switch (val) {
        case '类型段':
          typeGroup = this.fb.group({
            periodTypeId: [undefined, [Validators.required]],
            tempUpLogical: [{ value: undefined, disabled: true }, [Validators.required]],
            tempDownLogical: [{ value: undefined, disabled: true }, [Validators.required]],
          })
          break
        case '独立':
          typeGroup = this.fb.group({
            periodTypeId: [{ value: undefined, disabled: true }, [Validators.required]],
            tempUpLogical: [{ value: undefined, disabled: false }, [Validators.required]],
            tempDownLogical: [{ value: undefined, disabled: false }, [Validators.required]],
          })
          break
        case '维护':
          typeGroup = this.fb.group({
            typeId: [{ value: undefined, disabled: true }, [Validators.required]],
            tempUpLogical: [{ value: undefined, disabled: true }, [Validators.required]],
            tempDownLogical: [{ value: undefined, disabled: true }, [Validators.required]],
          })
          break
        case '关闭':
          typeGroup = this.fb.group({
            typeId: [{ value: undefined, disabled: true }, [Validators.required]],
            tempUpLogical: [{ value: undefined, disabled: true }, [Validators.required]],
            tempDownLogical: [{ value: undefined, disabled: true }, [Validators.required]],
          })
          break
        case '防冻':
          typeGroup = this.fb.group({
            typeId: [{ value: undefined, disabled: true }, [Validators.required]],
            tempUpLogical: [{ value: undefined, disabled: true }, [Validators.required]],
            tempDownLogical: [{ value: undefined, disabled: true }, [Validators.required]],
          })
          break
        default:
          break
      }
      modeGroup.setControl('typeGroup', typeGroup)
      this.validateForm.setControl('modeGroup', modeGroup)
      this.cdf.markForCheck()
      this.cdf.detectChanges()
      this.typeGroupVisible = true
      // console.log('validate updated')
      // setTimeout(() => {
      //   this.typeGroupVisible = true
      // }, 0)
    })
  }

  handleOpenModal(data) {
    if (!data) {
      this.validateForm.patchValue(this.search)
    } else {
      const modeName = data.modeName
      const modeGroup = this.validateForm.get('modeGroup')
      modeGroup.get('modeName').setValue(modeName)
      const typeGroup = modeGroup.get('typeGroup')
      if (modeName === '类型段') {
        typeGroup.get('periodTypeId').setValue(data.periodTypeId)
      } else {
        typeGroup.get('tempUpLogical').setValue(data.tempUpLogical)
        typeGroup.get('tempDownLogical').setValue(data.tempDownLogical)
      }
      const form = Object.assign({}, this.search, {
        terminalId: data.terminalId,
        terminalPosition: data.terminalPosition,
      })
      this.validateForm.patchValue(form)
      console.log('data: ', data)
      console.log('validate form: ', this.validateForm.getRawValue())
    }
    this.modalVisible = true
  }

  handleRangeTemperature(down, up) {
    return convertRangeTemperature(down, up)
  }

  handleConvertTemperature(temperature) {
    return convertTemperature(temperature)
  }
}

const validateTemperature = (control: FormGroup): ValidationErrors | null => {
  return null
}
