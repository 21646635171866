import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { copyObject, generateHttpPrams } from '@app/common/CommonUtil'
import { TEMP_HOST } from '@app/common/AliCloudResponse'

@Injectable({
  providedIn: 'root',
})
export class TaiyuanCollegeBuildService {
  constructor(private http: HttpClient) {}
  getColumn() {
    return [
      {
        key: 'premisesId',
        title: '楼宇Id',
      },
      {
        key: 'premisesName',
        title: '楼宇名称',
      },
      {
        key: 'accessMode',
        title: '接入模式',
      },
      {
        key: 'ipAddress',
        title: 'Ip地址/中继ID',
      },
      {
        key: 'groupCount',
        title: '分组数量',
      },
    ]
  }
  getData(data) {
    const param = data ? data : ''
    const url = `${TEMP_HOST}/rhthermo/eheating/configuration/query/premises/{accessmode}`
    const params = new HttpParams().set('am', param)
    return this.http
      .get<Array<any>>(url, { params: params })
      .toPromise()
  }

  getMoods() {
    return [
      {
        id: 1,
        name: '局域网接入',
      },
      {
        id: 2,
        name: '云端接入',
      },
    ]
  }

  handleOnInsert(data) {
    let params = copyObject(data, ['premisesId'])
    const url = `${TEMP_HOST}/rhthermo/eheating/configuration/add/premises`
    return this.http.put(url, params).toPromise()
  }

  handleOnUpdate(data) {
    const pid = data.premisesId
    delete data.premisesId
    const url = `${TEMP_HOST}/rhthermo/eheating/configuration/update/premises/${pid}`
    let params = copyObject(data)
    return this.http.post(url, params).toPromise()
  }

  handleOnDelete(id, status) {
    const url = `${TEMP_HOST}/rhthermo/eheating/configuration/switch/premises/${id}`
    return this.http
      .post(url, null, {
        params: {
          isDisabled: status,
        },
      })
      .toPromise()
  }
}
