import { NgModule } from '@angular/core'
import { DataHomeComponent } from '@app/pages/data-home/data-home.component'
import { DataChartBoxModule } from '@components/data-chart-box/data-chart-box.module'
import { RegionalEnergyConsumptionModule } from '@components/regional-energy-consumption/regional-energy-consumption.module'
import { AreaStateModule } from '@components/area-state/area-state.module'
import { TemperatureChartModule } from '@components/temperature-chart/temperature-chart.module'
import { DataHomeStatisticModule } from '@components/data-home-statistics/data-home-statistic.module'
import { DataHomeHeadModule } from '@components/data-home-head/data-home-head.module'
import { DataHomeMapModule } from '@components/data-home-map/data-home-map.module'

const modules = [
  DataHomeHeadModule,
  DataChartBoxModule,
  RegionalEnergyConsumptionModule,
  AreaStateModule,
  TemperatureChartModule,
  DataHomeStatisticModule,
  DataHomeMapModule,
]

@NgModule({
  imports: [...modules],
  exports: [],
  declarations: [DataHomeComponent],
})
export class DataHomeModule {}
