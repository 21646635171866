<div class="wrapper">
  <app-data-home-head></app-data-home-head>
  <div class="section-wrapper">
    <div class="bg-wrapper">
      <app-unity-webgl [url]="url"></app-unity-webgl>
    </div>
    <div class="upper-wrapper">
      <div class="left-wrapper">
        <app-regional-energy-consumption></app-regional-energy-consumption>
        <app-temperature-chart></app-temperature-chart>
      </div>
      <div class="center-wrapper">
        <app-data-home-statistics></app-data-home-statistics>
      </div>
      <div class="right-wrapper">
        <app-area-state></app-area-state>
      </div>
    </div>
  </div>
</div>
