import { Component, Input, OnInit, SimpleChanges } from '@angular/core'
// import * as L from '../../../assets/leaflet/leaflet-src.js'
import L from '../../../assets/leaflet/leaflet-indoor.js'

import { HttpClient, HttpParams } from '@angular/common/http'
import { OnChanges } from '@angular/core'
import { TaiyuanCollegeOperatingService } from '@components/taiyuan-college-operating/taiyuan-college-operating.service'

@Component({
  selector: 'app-taiyuan-college-leaflet',
  templateUrl: './taiyuan-college-leaflet.component.html',
  styleUrls: ['./taiyuan-college-leaflet.component.scss'],
})
export class TaiyuanCollegeLeafletComponent implements OnInit, OnChanges {
  constructor(private http: HttpClient, private service: TaiyuanCollegeOperatingService) {}

  ngOnInit(): void {
    this.handleOnLoadLeafLet()
  }

  @Input('premisesId')
  private premisesId
  @Input('floorId')
  private floorId

  handleInitLeafLet(data) {
    const osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      osm = new L.TileLayer(osmUrl, {
        maxZoom: 21,
        opacity: 0.5,
        attribution: 'Source: OpenStreetMap, 瑞海热控',
      })

    const map = new L.Map('map', {
      layers: [osm],
      center: new L.LatLng(37.6945, 112.6008),
      zoom: 17,
    })
    const legend = L.control({ position: 'topright' })

    legend.onAdd = function(map) {
      var d = '瑞海热控 太原大学项目' + '点击可以显示每个房间的运行详情'

      var div = L.DomUtil.create('div', 'info legend')

      div.appendChild(document.createTextNode(d))

      return div
    }

    legend.addTo(map)

    const indoorLayer = new L.Indoor(data, {
      getLevel: function(feature) {
        console.log('feature: ', feature)
        if (feature.properties.relations.length === 0) return null

        return feature.properties.relations[0].reltags.level
      },
      onEachFeature: function(feature, layer) {
        layer.bindPopup(
          JSON.stringify(
            '房间信息：' +
              feature.properties.relations[0].reltags.name +
              ' ' +
              feature.name +
              '</br>' +
              '温控器id：' +
              feature.properties.id +
              '</br>' +
              '供热模式：' +
              feature.properties.type +
              '</br>' +
              '加热状态：' +
              feature.properties.status +
              '</br>' +
              '连接状态：' +
              feature.properties.connection +
              '</br>' +
              '实时温度：' +
              feature.properties.temperature +
              ' °C',
            null,
            4,
          ).replace(/\"/g, ''),
        )
      },
      style: function(feature) {
        var fill = 'white'

        if (feature.properties.temperature === 'na') {
          fill = '#999999'
        } else if (feature.properties.temperature == 0) {
          fill = '#a9a9a9'
        } else if (feature.properties.temperature > 20) {
          fill = '#d95850'
        } else if (feature.properties.temperature > 15) {
          fill = '#eb8146'
        } else if (feature.properties.temperature > 8) {
          fill = '#f2d643'
        }

        return {
          fillColor: fill,
          weight: 1,
          color: '#666',
          fillOpacity: 1,
        }
      },
    })

    indoorLayer.setLevel('1')

    indoorLayer.addTo(map)

    const levelControl = new L.Control.Level({
      level: '1',
      levels: indoorLayer.getLevels(),
    })

    // Connect the level control to the indoor layer
    levelControl.addEventListener('levelchange', indoorLayer.setLevel, indoorLayer)

    levelControl.addTo(map)
  }

  ngOnChanges(changes: SimpleChanges): void {
    const floorId = changes['floorId'].currentValue
    const premisesId = changes['premisesId'].currentValue
    const status = new Boolean(floorId && premisesId)
    if (status.valueOf()) {
      this.service
        .handleGetLeafLetData({
          floorId: floorId,
          premisesId: premisesId,
        })
        .then(res => {
          const result = res.data.map(item => {
            return JSON.parse(item['geo_json'])
          })
          this.handleInitLeafLet(result)
        })
    } else {
      this.handleGetLocalLeafLetData()
    }
  }
  handleOnLoadLeafLet() {
    const status = new Boolean(this.floorId && this.premisesId)
    console.log('status: ', status)
    if (status.valueOf()) {
      this.service
        .handleGetLeafLetData({
          floorId: this.floorId,
          premisesId: this.premisesId,
        })
        .then(res => {
          const result = res.data.map(item => {
            return JSON.parse(item['geo_json'])
          })
          this.handleInitLeafLet(result)
        })
    } else {
      this.handleGetLocalLeafLetData()
    }
  }

  handleGetLocalLeafLetData() {
    this.http
      .get('assets/taiyuan_data.json')
      .toPromise()
      .then(data => {
        console.log('local data: ', data)
        // @ts-ignore
        this.handleInitLeafLet(data.features)
      })
  }
}
