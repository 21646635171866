<div class="area-state-wrapper">
  <div class="area-state-head-wrapper">
    <div class="area-state-head-title-wrapper">
      <div class="area-state-head-icon"></div>
      <div class="area-state-head-title">{{ title }}</div>
    </div>
  </div>
  <div class="separate-wrapper">
    <div class="separate"></div>
  </div>
  <div class="area-state-content-wrapper">
    <div class="area-regional-heat-wrapper">
      <div class="area-regional-heat-tab-wrapper">
        <nz-tabset (nzSelectChange)="handleTabsSelectChange($event)">
          <nz-tab *ngFor="let item of titles" [nzTitle]="item.name"> </nz-tab>
        </nz-tabset>
      </div>
      <div class="area-regional-heat-chart-wrapper">
        <div class="area-regional-chart-content-wrapper" *ngIf="lock === false">
          <div class="area-regional-chart-content" *ngFor="let item of currentData.indicators.left">
            <div class="area-regional-chart-content-title" nz-tooltip [nzTooltipTitle]="item.name">
              {{ item.name }}
            </div>
            <div class="area-regional-chart-content-number">{{ item.val }}</div>
          </div>
        </div>
        <div
          class="area-regional-chart"
          echarts
          [options]="areaRegionalOptions"
          [autoResize]="true"
          (chartClick)="handleChartClick($event)"
          (mouseout)="handleChartMouseOut($event)"
        ></div>
        <div class="area-regional-chart-content-wrapper" *ngIf="lock === false">
          <div
            class="area-regional-chart-content"
            *ngFor="let item of currentData.indicators.right"
          >
            <div class="area-regional-chart-content-title" nz-tooltip [nzTooltipTitle]="item.name">
              {{ item.name }}
            </div>
            <div class="area-regional-chart-content-number">{{ item.val }}</div>
          </div>
        </div>
      </div>
      <div *ngIf="lock === false" class="area-regional-title-wrapper">
        <div class="area-regional-title">项目设备数量</div>
        <div class="area-regional-title">项目总能耗</div>
      </div>
    </div>
    <div class="separate-wrapper">
      <div class="separate"></div>
    </div>
    <div class="area-state-temperature-wrapper">
      <div class="temperature-head-wrapper">
        <div class="temperature-title-wrapper">
          <div class="temperature-title">{{ temperatureTitle }}</div>
          <div class="separate-wrapper">
            <div class="separate"></div>
          </div>
        </div>
      </div>
      <div class="area-state-temperature-chart-wrapper">
        <div class="area-state-temperature-chart" echarts [options]="temperatureOptions"></div>
      </div>
    </div>
  </div>
</div>
