<div class="container-wrapper">
  <div class="bg-container"></div>
  <div class="content-wrapper">
    <div class="search-wrapper">
      <div class="form-item">
        <span>楼宇：</span>
        <nz-select [(ngModel)]="search.premisesId">
          <nz-option
            *ngFor="let item of premises"
            [nzValue]="item.premisesId"
            [nzLabel]="item.premisesName"
          ></nz-option>
        </nz-select>
      </div>
      <div class="operate">
        <nz-button-group>
          <button nz-button class="rhthermo-button-hover" (click)="handleGetData()">查询</button>
          <button
            [disabled]="floors.length === 0"
            class="rhthermo-button-hover"
            nz-button
            (click)="handleOnOpen(null)"
          >
            添加
          </button>
        </nz-button-group>
      </div>
    </div>
    <div class="table-wrapper">
      <nz-table #basicTable [nzData]="data" [nzShowPagination]="false">
        <thead>
          <tr>
            <th *ngFor="let column of columns" nzAlign="center">
              <span>{{ column.title }}</span>
            </th>
            <th class="pointer" nzAlign="center" style="color: #D4380D !important;">连接测试</th>
            <th nzAlign="center">修改</th>
            <th nzAlign="center">启停</th>
            <th nzAlign="center">删除楼层关联设备</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td nzAlign="center">{{ data.floorId }}</td>
            <td nzAlign="center">{{ data.floorName }}</td>
            <td nzAlign="center">{{ data.terminalCount }}</td>
            <td nzAlign="center">{{ data.modeCount }}</td>
            <td nzAlign="center">{{ data.typeCount }}</td>
            <td nzAlign="center" class="pointer" style="color: #D4380D;">测试</td>
            <td nzAlign="center" class="pointer" style="color: #D4380D;">
              <button nz-button class="rhthermo-table-text-button" (click)="handleOnOpen(data)">
                修改
              </button>
            </td>
            <td nzAlign="center">
              <nz-switch
                class="rhthermo-switch"
                [(ngModel)]="data.disabled"
                nzCheckedChildren="1"
                nzUnCheckedChildren="0"
                nzCheckedChildren="开"
                nzUnCheckedChildren="关"
                nzControl="true"
                (click)="handleOnSwitch(data)"
              ></nz-switch>
            </td>
            <td nzAlign="center" class="pointer">
              <button
                nz-button
                class="rhthermo-table-text-button"
                nz-popconfirm
                nzPopconfirmTitle="确定要删除吗?"
                (nzOnConfirm)="handleOnDeleteForConfirm(data)"
              >
                删除
              </button>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
  <nz-modal
    [(nzVisible)]="modalVisible"
    [nzTitle]="title + '楼层'"
    (nzOnCancel)="handleOnCancel()"
    (nzOnOk)="handleOnUpdate()"
    nzClassName="modal-wrapper"
  >
    <ng-container>
      <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOnUpdate()">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="premisesId">楼宇ID</nz-form-label>
          <nz-form-control nzErrorTip="楼宇ID不能为空">
            <nz-select formControlName="premisesId" nzAllowClear>
              <nz-option
                *ngFor="let item of premises"
                [nzValue]="item.premisesId"
                [nzLabel]="item.premisesName"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired nzFor="floorId">楼层ID</nz-form-label>
          <nz-form-control nzErrorTip="楼层ID不能为空">
            <nz-select formControlName="floorId" nzAllowClear>
              <nz-option *ngFor="let item of floors" [nzValue]="item" [nzLabel]="item"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired nzFor="floorName">楼层名称（位置）</nz-form-label>
          <nz-form-control nzErrorTip="楼层名称不能为空">
            <input nz-input formControlName="floorName" id="floorName" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzRequired nzFor="terminalCount">终端数量</nz-form-label>
          <nz-form-control nzErrorTip="请输入终端数量">
            <nz-input-number formControlName="terminalCount" id="terminalCount"></nz-input-number>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired nzFor="modeCount">模式数量</nz-form-label>
          <nz-form-control nzErrorTip="请输入模式数量">
            <nz-input-number formControlName="modeCount" id="modeCount"></nz-input-number>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired nzFor="typeCount">类型数量</nz-form-label>
          <nz-form-control nzErrorTip="请输入类型数量">
            <nz-input-number formControlName="typeCount" id="typeCount"></nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </form>
    </ng-container>
  </nz-modal>
</div>
