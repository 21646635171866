<div class="container-wrapper">
  <div class="bg-container"></div>
  <div class="content-wrapper">
    <div class="search-wrapper">
      <form nz-form [nzLayout]="'inline'" [formGroup]="search">
        <nz-form-item>
          <nz-form-label nzFor="sid">查询ID</nz-form-label>
          <nz-form-control>
            <input class="large-width" nz-input formControlName="sid" id="sid" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzFor="premisesId">楼宇</nz-form-label>
          <nz-form-control>
            <nz-select
              formControlName="premisesId"
              class="middle-width"
              nzAllowClear
              id="premisesId"
              (ngModelChange)="handleOnSearchPremiseSelectChange($event)"
            >
              <nz-option
                *ngFor="let item of premises"
                [nzValue]="item.premisesId"
                [nzLabel]="item.premisesName"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzFor="floorId">楼层</nz-form-label>
          <nz-form-control>
            <nz-select
              formControlName="floorId"
              class="middle-width"
              id="floorId"
              nzAllowClear
              (ngModelChange)="handleOnSearchFloorSelectChange($event)"
            >
              <nz-option
                *ngFor="let item of floors"
                [nzValue]="item.floorId"
                [nzLabel]="item.floorName"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzFor="modeName">供热模式</nz-form-label>
          <nz-form-control>
            <nz-select formControlName="modeName" class="large-width" nzAllowClear id="modeName">
              <nz-option
                *ngFor="let item of defaultPeriod"
                [nzValue]="item.modeName"
                [nzLabel]="item.modeName"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzFor="periodType">供热类型</nz-form-label>
          <nz-form-control>
            <nz-select
              formControlName="periodType"
              class="large-width"
              nzAllowClear
              id="periodType"
            >
              <nz-option
                *ngFor="let item of temperatureType"
                [nzValue]="item.periodType"
                [nzLabel]="item.periodType"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control class="operate">
            <button class="rhthermo-button-hover" nz-button (click)="handleOnSearch()">
              搜索
            </button>
            <button class="rhthermo-button-hover" type="reset" nz-button (click)="handleOnReset()">
              重置
            </button>
            <button class="rhthermo-button-hover" (click)="mapVisible = true" nz-button>
              查看可视化界面
            </button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
    <div class="table-wrapper">
      <nz-table #fixedTable [nzData]="data" [nzFrontPagination]="false" nzTableLayout="fixed">
        <thead (nzSortChange)="handleOnSortChange($event)">
          <tr>
            <th
              *ngFor="let column of columns"
              nzAlign="center"
              [nzWidth]="column.width"
              [nzEllipsis]="column.ellipsis ? true : false"
            >
              <span>{{ column.title }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of fixedTable.data">
            <td nzAlign="center" nzEllipsis nz-tooltip [nzTooltipTitle]="data.sid">
              {{ data.sid }}
            </td>
            <td nzAlign="center">{{ data.premisesName }}</td>
            <td nzAlign="center">{{ data.floorName }}</td>
            <td nzAlign="center">{{ data.terminalPosition }}</td>
            <td nzAlign="center" nzEllipsis nz-tooltip [nzTooltipTitle]="data.modeName">
              {{ data.modeName }}
            </td>
            <td nzAlign="center" nzEllipsis nz-tooltip [nzTooltipTitle]="data.periodType">
              {{ data.periodType }}
            </td>
            <td nzAlign="center">{{ data.periodBeginTime }} - {{ data.periodEndTime }}</td>
            <td nzAlign="center">
              {{ data.periodTempDown | temperature: data.periodTempUp }}
            </td>
            <td nzAlign="center">
              {{ data.runningType }}
            </td>
            <td nzAlign="center" nzEllipsis nz-tooltip [nzTooltipTitle]="data.tunnelTime">
              {{ data.tunnelTime }}
            </td>
            <td nzAlign="center">{{ data.temperatureNow }}</td>
            <td nzAlign="center">已连接</td>
          </tr>
        </tbody>
      </nz-table>
      <nz-pagination
        [(nzPageIndex)]="pagination.pageNum"
        [nzTotal]="totalCount"
        [nzShowQuickJumper]="true"
        [nzShowSizeChanger]="true"
        [nzPageSizeOptions]="[10, 20, 30, 40, 50]"
        [(nzPageSize)]="pagination.pageSize"
        (nzPageIndexChange)="handleOnPageIndexChange($event)"
        (nzPageSizeChange)="handleOnPageSizeChange($event)"
      ></nz-pagination>
    </div>
  </div>
  <nz-drawer
    [nzVisible]="visibleStatus"
    nzPlacement="right"
    nzTitle="操作记录"
    (nzOnClose)="visibleStatus = false"
    [nzWidth]="356"
  >
    <nz-list nzBordered>
      <nz-list-item *ngFor="let item of records">
        <span nz-typography>{{ item.people }}</span>
        {{ item.operaTime }}
      </nz-list-item>
    </nz-list>
  </nz-drawer>
  <nz-drawer
    *ngIf="mapVisible"
    [nzVisible]="mapVisible"
    nzPlacement="left"
    nzTitle="可视化界面"
    (nzOnClose)="mapVisible = false"
    [nzWidth]="950"
  >
    <app-taiyuan-college-leaflet
      [premisesId]="this.search.controls['premisesId'].value"
      [floorId]="this.search.controls['floorId'].value"
    ></app-taiyuan-college-leaflet>
  </nz-drawer>
</div>
