import { Component, OnInit } from '@angular/core'
import { TaiyuanTypeTimeSettingService } from '@components/taiyuan-type-time-setting/taiyuan-type-time-setting.service'
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { CommonService } from '@app/common/CommonService'
import * as moment from 'moment'
import { NzModalService } from 'ng-zorro-antd'

@Component({
  selector: 'app-taiyuan-type-time-setting',
  templateUrl: './taiyuan-type-time-setting.component.html',
  styleUrls: ['./taiyuan-type-time-setting.component.scss'],
})
export class TaiyuanTypeTimeSettingComponent implements OnInit {
  columns = []
  data: Array<any> = []
  childData = []
  premised = []
  floors = []
  search = {
    premisesId: '',
    floorId: '',
  }

  validateForm: FormGroup = this.fb.group({
    premisesId: [{ value: null, disabled: true }, [Validators.required]],
    floorId: [{ value: null, disabled: true }, [Validators.required]],
    periodTypeId: [null, [Validators.required]],
    periodType: [null, [Validators.required]],
    periodTypeSegs: new FormControl(0, [Validators.required, Validators.max(6), Validators.min(1)]),
    typeChild: new FormArray([], validates),
  })

  get validate() {
    let status = true
    for (const item in this.validateForm.controls) {
      status = status && this.validateForm.get(item).valid
    }
    return status
  }

  modalTitle: string = '修改'
  childColumns = []
  childVisible = false
  modalVisible = false

  constructor(
    private commonService: CommonService,
    private service: TaiyuanTypeTimeSettingService,
    private fb: FormBuilder,
    private modal: NzModalService,
  ) {}

  ngOnInit(): void {
    this.columns = this.service.getColumns()
    this.handleInitSearch()
    this.validateForm.get('periodTypeSegs').valueChanges.subscribe(val => {
      this.handleOnTypeCountChange(val)
    })
  }

  handleGetData() {
    this.service.getData(this.search).then(res => {
      const result: Array<any> = JSON.parse(res)
      result.forEach(item => {
        item.segment.forEach(child => {
          child.periodType = item.periodType
        })
      })
      this.data = result
    })
  }

  handleOnDelete(data: object) {
    this.service.handleDelete(data)
  }

  handleOnPremiseSelectChange(event) {
    this.floors = []
    this.search.floorId = null
    if (!event) {
      return
    }
    this.commonService.handleGetFloors(event).then(res => {
      this.floors = res.data
      this.search.floorId = this.floors[0].floorId || ''
    })
  }

  handleGetChildData(segment: Array<any>) {
    this.childVisible = true
    this.childData = segment
  }

  handleOnSwitch(data: any) {
    const title = data.disabled ? '关停' : '开启'
    const content = data.disabled ? '确定要关闭吗？' : '确定重新开启此楼吗？'
    const isDisabled = data.disabled ? '1' : '0'
    this.modal.confirm({
      nzTitle: `<i>${title}</i>`,
      nzContent: `${content}`,
      nzOnOk: () => {
        this.service
          .handleSwitch({ pid: this.search.premisesId, fid: data.floorId, isDisabled: isDisabled })
          .then(() => this.handleGetData())
      },
    })
  }

  handleGetChildColumns() {
    this.childColumns = this.service.getChildColumns()
  }

  handleOnUpdate() {
    console.log('validate form: ', this.validateForm)
    console.log('validate form valid: ', this.validateForm.valid)
  }

  handleOnOpen(data) {
    let param
    if (!data) {
      param = Object.assign(this.search, {
        periodTypeId: null,
        periodType: null,
        periodTypeSegs: 0,
      })
      console.log('this from: ', this.validateForm.getRawValue())
      this.modalTitle = '添加'
      this.validateForm.patchValue(param)
      console.log('this from: ', this.validateForm.getRawValue())
    } else {
      param = Object.assign(this.search, {
        periodTypeId: data.periodTypeId,
        periodType: data.periodType,
        periodTypeSegs: data.periodTypeSegs,
      })
      this.validateForm.patchValue(param)
      const subData = data.segment
      const childData = subData.map(item => {
        let times = item.periodBeginTime.split(':')
        return {
          // @ts-ignore
          periodBeginTime: moment({ hour: times[0], minute: times[1] }).toDate(),
          periodTempUp: item.periodTempUp,
          periodTempDown: item.periodTempDown,
        }
      })
      console.log('child data: ', childData)
      this.handleOnTypeCountChange(data.periodTypeSegs, childData)
      console.log('update data: ', param, ' -> ', data)
      console.log('validate form value: ', this.validateForm.value)
    }
    this.modalVisible = true
  }

  private handleInitSearch() {
    this.handleGetChildColumns()
    this.commonService.handleGetPremises().then(res => {
      this.premised = res.data.rows
      if (this.premised.length !== 0) {
        this.search.premisesId = this.premised[0].premisesId
        this.commonService.handleGetFloors(parseInt(this.search.premisesId)).then(res => {
          this.floors = res.data
          console.log('this floors len: ', this.floors)
          if (this.floors.length > 0) {
            this.search.floorId = this.floors[0].floorId
            this.handleGetData()
          }
        })
      }
    })
  }

  handleOnTypeCountChange(
    val,
    childData?: Array<{
      periodBeginTime: Date
      periodTempDown: number
      periodTempUp: number
    }>,
  ) {
    if (!childData) {
      childData = []
      for (let i = 0; i < val; i++) {
        childData.push({
          periodBeginTime: null,
          periodTempDown: undefined,
          periodTempUp: undefined,
        })
      }
    }
    const typeChild = this.validateForm.get('typeChild') as FormArray
    typeChild.clear()
    const len = val
    for (let i = 0; i < len; i++) {
      typeChild.push(
        this.fb.group({
          periodBeginTime: new FormControl(childData[i].periodBeginTime || null, {
            updateOn: 'blur',
            validators: [Validators.required],
          }),
          periodTempDown: new FormControl(childData[i].periodTempDown || '', {
            updateOn: 'blur',
            validators: [Validators.required],
          }),
          periodTempUp: new FormControl(childData[i].periodTempUp || '', {
            updateOn: 'blur',
            validators: [Validators.required],
          }),
        }),
      )
    }
    typeChild.setValidators(validates)
  }

  handleOnCloseModal() {
    this.modalVisible = false
    this.validateForm.reset({
      premisesId: this.search.premisesId,
      floorId: this.search.floorId,
      periodTypeId: null,
      periodType: null,
      periodTypeSegs: 0,
      typeChild: [],
    })
    this.handleOnTypeCountChange(0)
    console.log('validate form: ', this.validateForm.value)
  }
}

const validates: ValidatorFn = (control: FormArray): ValidationErrors | null => {
  const len = control.length
  if (len === 0 || len === 1) {
    return null
  }
  for (let i = 1; i < len; i++) {
    const prev = control.get('' + (i - 1)).get('periodBeginTime').value
    const curr = control.get('' + i).get('periodBeginTime').value
    if (moment(prev).isAfter(curr) || moment(prev).isSame(curr)) {
      return { error: 'time disorder' }
    }
  }
  return null
}
