import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { LOCALE_ID, NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { JwtModule } from '@auth0/angular-jwt'

import { NgProgressModule } from '@ngx-progressbar/core'
import { NgProgressRouterModule } from '@ngx-progressbar/router'
import { NgProgressHttpModule } from '@ngx-progressbar/http'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import store from 'store'
import { metaReducers, reducers } from './store/reducers'
import { UserEffects } from './store/user/effects'
import { jwtAuthService } from './services/jwt'
import { MockHttpCallInterceptor } from './services/fakeApi'
// locale registration
import { registerLocaleData } from '@angular/common'
import { default as localZH } from '@angular/common/locales/zh'

import { NZ_I18N, zh_CN as localeZorro } from 'ng-zorro-antd'
import { ComponentsModule } from '@components/components.module'
import { LoginPageComponent } from './pages/login-page/login-page.component'
import { NzNotificationModule } from 'ng-zorro-antd/notification'
import { NzMessageModule } from 'ng-zorro-antd/message'
import { DataHomeModule } from '@app/pages/data-home/data-home.module'
import { DatavWebglModule } from '@app/pages/datav-webgl/datav-webgl.module'
import { PipeModule } from '@app/pipes/pipe-module.modules'

const LOCALE_PROVIDERS = [
  { provide: LOCALE_ID, useValue: 'zh_CN' },
  { provide: NZ_I18N, useValue: localeZorro },
]
registerLocaleData(localZH, 'zh')

export function tokenGetter() {
  return store.get('accessToken')
}

@NgModule({
  declarations: [AppComponent, LoginPageComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NzNotificationModule,
    NzMessageModule,

    ComponentsModule,
    DataHomeModule,
    DatavWebglModule,

    // translate
    TranslateModule.forRoot(),

    // ngrx
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([UserEffects]),
    StoreRouterConnectingModule.forRoot(),

    // nprogress
    NgProgressModule.withConfig({
      thick: true,
      spinner: false,
      color: '#0190fe',
    }),
    NgProgressRouterModule,
    NgProgressHttpModule,
    PipeModule,

    // JWT
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ['api.rhthermo.com'],
      },
    }),
  ],
  providers: [
    // auth service
    jwtAuthService,

    // fake http interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MockHttpCallInterceptor,
      multi: true,
    },

    // locale providers
    ...LOCALE_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
