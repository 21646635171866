import { Component, OnDestroy, OnInit } from '@angular/core'
import UnityWebgl from 'unity-webgl'
@Component({
  selector: 'app-taiyuan-college-supervision',
  templateUrl: './taiyuan-college-supervision.component.html',
  styleUrls: ['./taiyuan-college-supervision.component.scss'],
})
export class TaiyuanCollegeSupervisionComponent implements OnInit, OnDestroy {
  constructor() {}

  private unityInstance

  ngOnInit(): void {
    const buildUrl = '../../../assets/unity-sources/heatPump/Build'
    this.unityInstance = new UnityWebgl({
      loaderUrl: `${buildUrl}/heatPumpWeb.loader.js`,
      dataUrl: `${buildUrl}/heatPumpWeb.data`,
      frameworkUrl: `${buildUrl}/heatPumpWeb.framework.js`,
      codeUrl: `${buildUrl}/heatPumpWeb.wasm`,
      streamingAssetsUrl: `StreamingAssets`,
      companyName: `DefaultCompany`,
      productName: `BIM`,
      productVersion: '0.1',
    })
    this.unityInstance.create(document.querySelector('#unityContainer'))
  }

  ngOnDestroy(): void {
    this.unityInstance.destroy()
  }
}
