import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-unity-webgl',
  templateUrl: './unity-webgl.component.html',
  styleUrls: ['./unity-webgl.component.scss'],
})
export class UnityWebglComponent implements OnInit {
  @Input()
  url = ''
  unityInstance: any
  constructor() {}

  ngOnInit(): void {
    const loader = (window as any).UnityLoader
    this.unityInstance = loader.instantiate('unityContainer', this.url)
  }
}
