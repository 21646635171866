import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { TERMINAL_LIST } from '@app/common/TaidaApi'

export interface TerminalModel {
  terminalPositionId: number
  terminalPosition: string
  typeId: number
  typeName: string
  tempRangePhysics: string
  tempPhysicsDownLimits: string
  tempLogicalUpLimits: string
  tempLogicalDownLimits: string
}
@Injectable({
  providedIn: 'root',
})
export class TaiyuanCollegeTerminalService {
  constructor(private http: HttpClient) {}
  getColumns() {
    return [
      {
        key: 'terminalPositionId',
        title: '温控器ID',
      },
      {
        key: 'terminalPosition',
        title: '温控器名称',
      },
      {
        kye: 'modeId',
        title: '模式ID',
      },
      {
        key: 'modeName',
        title: '模式名称',
      },
      {
        key: 'typeId',
        title: '类型ID',
      },
      {
        key: 'typeName',
        title: '类型名称',
      },
      {
        key: 'tempRangePhysics',
        title: '温控终端温度上下限',
        width: '120px',
      },
      {
        key: 'tempCompRangePhysics',
        title: '温控终端温度补偿',
      },
    ]
  }

  getData(param): Promise<Array<any>> {
    return this.http
      .get<Array<any>>(TERMINAL_LIST, { params: param })
      .toPromise()
  }

  getModes(param: object): Promise<Array<any>> {
    const data = [
      {
        modeName: '独立',
        temperatureDownlimit: undefined,
        termperatureUplimit: undefined,
      },
      {
        modeName: '维护',
        temperatureDownlimit: undefined,
        termperatureUplimit: undefined,
      },
      {
        modeName: '类型段',
        temperatureDownlimit: undefined,
        termperatureUplimit: undefined,
      },
      {
        modeName: '关闭',
        temperatureDownlimit: undefined,
        termperatureUplimit: undefined,
      },
      {
        modeName: '维护',
        temperatureDownlimit: undefined,
        termperatureUplimit: undefined,
      },
    ]
    return new Promise((resolve, reject) => {
      resolve(data)
    })
  }

  getTypes(param: object): Promise<Array<any>> {
    const data = [
      {
        typeId: 1,
        typeName: '宿舍',
      },
      {
        typeId: 2,
        typeName: '卫生间',
      },
      {
        typeId: 3,
        typeName: '走廊',
      },
    ]
    return new Promise((resolve, reject) => {
      resolve(data)
    })
  }

  getTerminalId(param: object): Promise<Array<number>> {
    const data = [1, 2, 3, 4, 5]
    return new Promise((resolve, reject) => {
      resolve(data)
    })
  }

  handleSwitch(param: { fid: any; pid: string; isDisabled: string }) {
    return new Promise((resolve, reject) => {
      resolve()
    })
  }
}
