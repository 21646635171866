import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { TaiyuanCollegeBuildService } from '@components/taiyuan-college-build/taiyuan-college-build.service'
import { NzModalService } from 'ng-zorro-antd'

@Component({
  selector: 'app-taiyuan-college-build',
  templateUrl: './taiyuan-college-build.component.html',
  styleUrls: ['./taiyuan-college-build.component.scss'],
})
export class TaiyuanCollegeBuildComponent implements OnInit {
  data: Array<any> = []
  columns: Array<any> = []
  modalVisible: boolean = false
  search = {
    mode: null,
  }
  validateForm!: FormGroup
  moods = []
  title: String = '修改'
  isUpdate: boolean = true
  checked: boolean = false
  maxValue: number = Number.MAX_SAFE_INTEGER

  constructor(
    private service: TaiyuanCollegeBuildService,
    private fb: FormBuilder,
    private modal: NzModalService,
  ) {}

  ngOnInit(): void {
    this.columns = this.service.getColumn()
    this.moods = this.service.getMoods()
    this.handleOnSearch()
    this.handleInitForm()
  }

  handleOnDelete() {}

  handleOnUpdate() {
    for (const item in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(item)) {
        this.validateForm.controls[item].markAsDirty()
        this.validateForm.controls[item].updateValueAndValidity()
      }
    }
    if (this.validateForm.valid) {
      if (this.validateForm.controls['premisesId'].value != null) {
        this.service
          .handleOnUpdate(Object.assign({}, this.validateForm.value, { isDisabled: '0' }))
          .then(() => {
            this.handleOnSearch()
            this.handleOnCancel()
          })
      } else {
        this.service.handleOnInsert(this.validateForm.value).then(() => {
          this.handleOnSearch()
          this.handleOnCancel()
        })
      }
    } else {
      console.log('checked false')
    }
  }

  handleOnCancel() {
    if (this.validateForm) {
      this.validateForm.reset()
    }
    this.modalVisible = false
  }

  handleInitForm() {
    this.validateForm = this.fb.group({
      premisesId: null,
      premisesName: [null, [Validators.required]],
      ipAddress: [
        null,
        [
          Validators.required,
          Validators.pattern(
            /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/,
          ),
        ],
      ],
      accessMode: [null, [Validators.required]],
      groupCount: [null, [Validators.required]],
      // 校时间隔
      timeSyncInterval: [{ value: 60, disabled: true }],
      // 采集数据间隔
      collectDataInterval: [{ value: 1, disabled: true }],
      // 锁机间隔
      lockedInterval: [{ value: 45, disabled: true }],
      // 终端执行 mod
      terminalExecutionAsync: [{ value: 3, disabled: true }],
      // 异步采样 mod
      collectDataAsync: [{ value: 1, disabled: true }],
    })
  }

  handleOnOpen(data: Object = null) {
    this.modalVisible = true
    if (!data) {
      this.isUpdate = false
      this.title = '添加'
      return
    }
    this.isUpdate = true
    this.validateForm.patchValue(data)
  }

  handleOnSearch() {
    this.service.getData(this.search.mode).then(res => {
      this.data = res
      this.data.forEach(item => {
        item.disabled = item.isDisabled === '0' ? true : false
        item.timeSyncInterval = 60
        item.collectDataInterval = 1
        item.lockedInterval = 45
        item.terminalExecutionAsync = 3
        item.collectDataAsync = 1
      })
    })
  }

  handleOnSwitch(data) {
    if (data.disabled) {
      this.modal.confirm({
        nzTitle: '<i>关停</i>',
        nzContent: '<b>确定要关闭吗？</b>',
        nzOnOk: () => {
          this.service.handleOnDelete(data.premisesId, '1').then(() => {
            data.isDisabled = '1'
            this.handleOnSearch()
          })
        },
      })
    } else {
      this.modal.confirm({
        nzTitle: '<i>开启</i>',
        nzContent: '<b>确定重新开启此楼吗？</b>',
        nzOnOk: () => {
          this.service.handleOnDelete(data.premisesId, '0').then(() => {
            data.isDisabled = '0'
            this.handleOnSearch()
          })
        },
      })
    }
  }

  handleOnDeleteTerminal(data: object) {}
}
