import { Component, OnInit } from '@angular/core'
import { DataHomeStatisticService } from '@service/data-report/data-home-statistic.service'

@Component({
  selector: 'app-data-home-statistics',
  templateUrl: './data-home-statistics.component.html',
  styleUrls: ['./data-home-statistics.component.scss'],
})
export class DataHomeStatisticsComponent implements OnInit {
  title: string = '数据可视化'
  constructor(private service: DataHomeStatisticService) {}
  columns = [
    {
      name: '加热设备总数',
      prop: 'heating_tol',
    },
    {
      name: '闲置设备总数',
      prop: 'device_idle_tol',
    },
    {
      name: '故障设备总数',
      prop: 'device_fault_tol',
    },
    {
      name: '传感设备总数',
      prop: 'device_opera_tol',
    },
  ]
  data: Array<{
    name: string
    prop: string
    val: string
  }> = null
  ngOnInit(): void {
    this.service.getData().subscribe(res => {
      this.data = this.columns.map(item => {
        return {
          name: item.name,
          prop: item.prop,
          val: res.data[0][item.prop],
        }
      })
    })
  }
}
