import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TaiyuanCollegeHeapOperationComponent } from './taiyuan-college-heap-operation.component'
import {
  NzButtonModule,
  NzDrawerModule,
  NzFormModule,
  NzListModule,
  NzPaginationModule,
  NzSelectModule,
  NzTableModule,
  NzToolTipModule,
} from 'ng-zorro-antd'
import { ReactiveFormsModule } from '@angular/forms'

@NgModule({
  declarations: [TaiyuanCollegeHeapOperationComponent],
  exports: [TaiyuanCollegeHeapOperationComponent],
  imports: [
    NzFormModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzTableModule,
    NzToolTipModule,
    NzDrawerModule,
    NzListModule,
    NzButtonModule,
    NzPaginationModule,
  ],
})
export class TaiyuanCollegeHeapOperationModule {}
