import { NgModule } from '@angular/core'
import { DataChartBoxModule } from '@components/data-chart-box/data-chart-box.module'
import { RegionalEnergyConsumptionComponent } from '@components/regional-energy-consumption/regional-energy-consumption.component'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { CommonModule } from '@angular/common'

@NgModule({
  imports: [DataChartBoxModule, NzToolTipModule, CommonModule],
  exports: [RegionalEnergyConsumptionComponent],
  declarations: [RegionalEnergyConsumptionComponent],
})
export class RegionalEnergyConsumptionModule {}
