import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { AliCloudResponse, appCode, prefix_url } from '@app/common/AliCloudResponse'
import { COLLEGE_OPERATING, TEMPERATURE_MODE, TEMPERATURE_TYPE } from '@app/common/TaidaApi'
import { FormGroup } from '@angular/forms'

@Injectable({
  providedIn: 'root',
})
export class TaiyuanCollegeHeapOperationService {
  constructor(private http: HttpClient) {}

  getColumns() {
    return [
      {
        key: 'sid',
        title: '查询ID',
        width: '100px',
        ellipsis: true,
      },
      {
        key: 'premisesName',
        title: '楼宇',
        width: '150px',
      },
      {
        key: 'floorName',
        title: '楼层',
      },
      {
        key: 'position',
        title: '分水器位置',
      },
      {
        key: 'prevTime',
        title: '上次信息时间',
        width: '120px',
      },
      {
        key: 'angel',
        title: '阀门角度',
      },
      {
        key: 'account',
        title: '流量',
      },
      {
        key: 'temperature',
        title: '实际温度',
      },
      {
        key: 'status',
        title: '连接状态',
      },
    ]
  }

  getSort() {
    return {
      energy: true,
      temperature: true,
    }
  }

  handleOnSearch(searchModel, pagination) {
    const position = [
      101,
      102,
      103,
      104,
      105,
      106,
      111,
      112,
      120,
      121,
      122,
      123,
      '走道',
      'wc',
      '楼梯1',
      '楼梯2',
      '楼梯3',
    ]
    const angel = [
      '15°',
      '45°',
      '65°',
      '0°',
      '0°',
      '30°',
      '0°',
      '45°',
      '45°',
      '45°',
      '45°',
      '45°',
      '45°',
      '45°',
      '15°',
      '60°',
      '0°',
    ]
    const account = [
      '0.5',
      '1.5',
      '2',
      '0',
      '0',
      '1',
      '0',
      '1.5',
      '1.5',
      '1.5',
      '1.5',
      '1.5',
      '1.5',
      '0.5',
      '1.5',
      '2',
      '0',
    ]
    const temperature = [
      '42℃ / 33 ℃',
      '41℃ / 30 ℃',
      '41℃ / 33 ℃',
      '41℃ / 30 ℃',
      '42℃ / 30 ℃',
      '42℃ / 33 ℃',
      '42℃ / 30 ℃',
      '40℃ / 30 ℃',
      '43℃ / 33 ℃',
      '40℃ / 33 ℃',
      '43℃ / 33 ℃',
      '43℃ / 30 ℃',
      '40℃ / 32 ℃',
      '41℃ / 31 ℃',
      '40℃ / 32 ℃',
      '43℃ / 30 ℃',
      '42℃ / 33 ℃',
    ]
    const data = []
    for (let i = 0; i < 17; i++) {
      data.push({
        sid: `11-1-1${i + 1 < 10 ? '0' + (i + 1) : i + 1}`,
        premisesName: '教学1楼',
        floorName: '1层A',
        position: position[i],
        status: '开启状态',
        prevTime: '2021-12-19 11:30:00',
        angel: angel[i],
        account: account[i] + 'm³/h',
        temperature: temperature[i],
      })
    }
    const result: AliCloudResponse = {
      apiLog: 1,
      errMsg: '1',
      requestId: '123',
      errCode: 0,
      data: {
        rows: data,
        totalNumber: 100,
      },
    }
    return Promise.resolve(result)
  }

  handleOnReset(searchModel) {
    for (let item in searchModel) {
      searchModel[item].reset()
    }
  }

  handleGetTemperatureType(params) {
    const param = Object.assign(params, { appCode })
    const url = `${prefix_url}${TEMPERATURE_TYPE}`
    return this.http
      .get<AliCloudResponse>(url, { params: param })
      .toPromise()
  }
  handleGetTemperatureMode(params) {
    const param = Object.assign(params, { appCode })
    console.log('mode params: ', param)
    const url = `${prefix_url}${TEMPERATURE_MODE}`
    return this.http
      .get<AliCloudResponse>(url, { params: param })
      .toPromise()
  }

  handleGetLeafLetData(search: FormGroup) {
    const param = {
      premisesId: search.controls['premisesId'].value,
      floorId: search.controls['floorId'].value,
      appCode: '3cbf3469059b491a91c7f2a43889d381',
    }
    const url =
      'http://c3e35869d12944cbba6b8ba19f5b2182-cn-beijing.alicloudapi.com/rhthermo/eheating/tyxy/operating/v1/leaflet'
    return this.http
      .get<AliCloudResponse>(url, { params: param })
      .toPromise()
  }
}
