import { NgModule } from '@angular/core'
import { DataHomeStatisticsComponent } from '@components/data-home-statistics/data-home-statistics.component'
import { CommonModule } from '@angular/common'

@NgModule({
  declarations: [DataHomeStatisticsComponent],
  exports: [DataHomeStatisticsComponent],
  imports: [CommonModule],
})
export class DataHomeStatisticModule {}
