import { TEMP_HOST } from '@app/common/AliCloudResponse'
const COMMON_PREFIX_URL = `${TEMP_HOST}/rhthermo/eheating/configuration`
const COLLEGE_OPERATING = '/rhthermo/eheating/tyxy/operating'
const COLLEGE_PREMISES = '/rhthermo/eheating/tyxy/entity/premises'
const COLLEGE_FLOOR = '/rhthermo/eheating/tyxy/entity/floor'
const COLLEGE_TERMINAL = '/rhthermo/eheating/tyxy/entity/device'
const COLLEGE_PERIOD_TYPE = '/rhthermo/eheating/tyxy/entity/periodtype'
// 供热模式
const TEMPERATURE_TYPE = '/rhthermo/eheating/tyxy/entity/periodtype'
// 供热类型
const TEMPERATURE_MODE = '/rhthermo/eheating/tyxy/entity/mode'
const SETTING_CONFIGURATION_QUERY = `${TEMP_HOST}/rhthermo/eheating/configuration/query/system/1`
const SETTING_CONFIGURATION_UPDATE = `${TEMP_HOST}/rhthermo/eheating/configuration/update/system/1`
const QUERY_FLOORS = `${COMMON_PREFIX_URL}/query/floors`
const NO_INSERT_FLOORS = `${COMMON_PREFIX_URL}/query/floors/idlist`
// 更新楼层
const UPDATE_FLOORS = `${TEMP_HOST}/rhthermo/eheating/configuration/update/floor`
const INSERT_FLOORS = `${COMMON_PREFIX_URL}/add/floor`
const SWITCH_FLOORS = `${COMMON_PREFIX_URL}/switch/floor`
const MODE_LIST = `${COMMON_PREFIX_URL}/query/modelist`
const MODE_MODIFY = `${COMMON_PREFIX_URL}/update/mode`
const TYPE_LIST = `${COMMON_PREFIX_URL}/query/type`
const TERMINAL_LIST = `${COMMON_PREFIX_URL}/query/terminal`
export {
  COLLEGE_OPERATING,
  COLLEGE_FLOOR,
  COLLEGE_PERIOD_TYPE,
  COLLEGE_PREMISES,
  COLLEGE_TERMINAL,
  SETTING_CONFIGURATION_UPDATE,
  SETTING_CONFIGURATION_QUERY,
  NO_INSERT_FLOORS,
  UPDATE_FLOORS,
  INSERT_FLOORS,
  QUERY_FLOORS,
  SWITCH_FLOORS,
  MODE_LIST,
  MODE_MODIFY,
  TEMPERATURE_TYPE,
  TEMPERATURE_MODE,
  TYPE_LIST,
  TERMINAL_LIST,
}
