export const getMenuData: any[] = [
  {
    category: true,
    title: '功能界面',
  },
  {
    title: '控制界面',
    key: 'kongzhi',
    icon: 'fa fa-tachometer',
    url: '/dashboard',
    children: [
      {
        title: '区域总控制台',
        key: 'kongzhi',
        icon: 'fa fa-tachometer',
        url: '/dashboard',
      },
      {
        title: '项目',
        key: 'quyu',
        icon: 'fa fa-map',
        url: '/dashboard/region',
        children: [
          {
            title: '山西项目导航',
            key: 'shanxi',
            url: '/dashboard/region/shanxi',
            children: [
              {
                title: '太原学院项目',
                key: 'taiyuanxueyuan',
                url: '/dashboard/region/shanxi/1',
              },
              {
                title: '山西同煤大厦',
                key: 'shanxitongmeidasha',
                url: '/dashboard/region/shanxi/2',
              },
            ],
          },
          {
            title: '陕西项目导航',
            key: 'shaanxi',
            url: '/dashboard/region/shaanxi',
            children: [],
          },
          {
            title: '北京项目导航',
            key: 'beijing',
            url: '/dashboard/region/beijing',
            children: [],
          },
          {
            title: '上海项目导航',
            key: 'shanghai',
            url: '/dashboard/region/shanghai',
            children: [],
          },
        ],
      },
    ],
  },
  {
    title: '数据可视化项目',
    key: 'dataReport',
    icon: 'fa fa-tachometer',
    url: '/datav',
    children: [
      {
        title: '数据大屏可视化',
        key: 'datav',
        icon: 'fa fa-tachometer',
        url: 'datav',
        meta: {
          isOutSide: false,
          target: 'blank',
        },
      },
      {
        title: 'Bim数据大屏可视化',
        key: 'datav-bim',
        icon: 'fa fa-tachometer',
        url: 'datav-bim',
        meta: {
          isOutSide: false,
          target: 'blank',
        },
      },
      {
        title: '嵌入网页测试',
        key: 'iframe',
        icon: 'fa fa-tachometer',
        url: 'https://xd.adobe.com/view/45dd26c8-151d-4c44-a0cc-4848793153f1-108e/?fullscreen',
        meta: {
          isOutSide: true,
          target: '',
        },
      },
    ],
  },
  // {
  //   title: '太原学院',
  //   key: 'taiyuan',
  //   icon: 'fa fa-tachometer',
  //   url: '/taiyuan',
  //   children: [
  //     {
  //       title: '运营详情',
  //       key: 'ty-operating',
  //       icon: 'fa fa-tachometer',
  //       url: '/taiyuan/operating',
  //     },
  //     {
  //       title: '系统设置',
  //       key: 'ty-system',
  //       icon: 'fa fa-tachometer',
  //       url: '/taiyuan/system',
  //     },
  //     {
  //       title: '楼宇设置',
  //       key: 'ty-build',
  //       icon: 'fa fa-tachometer',
  //       url: '/taiyuan/build',
  //     },
  //     {
  //       title: '楼层设置',
  //       key: 'ty-build',
  //       icon: 'fa fa-tachometer',
  //       url: '/taiyuan/floor',
  //     },
  //     {
  //       title: '模式设置',
  //       key: 'ty-mode',
  //       icon: 'fa fa-tachometer',
  //       url: '/taiyuan/mode',
  //     },
  //     {
  //       title: '温控器设置',
  //       key: 'ty-build',
  //       icon: 'fa fa-tachometer',
  //       url: '/taiyuan/terminal',
  //     },
  //     {
  //       title: '类型及时段设置',
  //       key: 'ty-build',
  //       icon: 'fa fa-tachometer',
  //       url: '/taiyuan/typetime',
  //     },
  //   ],
  // },
  {
    title: '山西项目',
    icon: 'fa fa-tachometer',
    key: 'sx',
    url: 'javascript: void(0)',
    children: [
      {
        title: '太原学院',
        icon: 'fa fa-tachometer',
        key: 'taida',
        url: '/taiyuan',
        children: [
          {
            title: '电采暖',
            icon: 'fa fa-tachometer',
            key: 'electric',
            url: '/electric',
            children: [
              {
                title: '运营详情',
                key: 'ty-operating',
                icon: 'fa fa-tachometer',
                url: '/taiyuan/operating',
              },
              {
                title: '系统设置',
                key: 'ty-system',
                icon: 'fa fa-tachometer',
                url: '/taiyuan/system',
              },
              {
                title: '楼宇设置',
                key: 'ty-build',
                icon: 'fa fa-tachometer',
                url: '/taiyuan/build',
              },
              {
                title: '楼层设置',
                key: 'ty-build',
                icon: 'fa fa-tachometer',
                url: '/taiyuan/floor',
              },
              {
                title: '模式设置',
                key: 'ty-mode',
                icon: 'fa fa-tachometer',
                url: '/taiyuan/mode',
              },
              {
                title: '温控器设置',
                key: 'ty-build',
                icon: 'fa fa-tachometer',
                url: '/taiyuan/terminal',
              },
              {
                title: '类型及时段设置',
                key: 'ty-build',
                icon: 'fa fa-tachometer',
                url: '/taiyuan/typetime',
              },
            ],
          },
          {
            title: '空气源热泵',
            icon: 'fa fa-tachometer',
            key: 'electric',
            url: 'javascript: void(0)',
            children: [
              {
                title: '运营详情',
                key: 'ty-kqy-operating',
                icon: 'fa fa-tachometer',
                url: '/taiyuan/koperation',
              },
              {
                title: '热源监控',
                key: 'ty-kqy-supervisor',
                icon: 'fa fa-tachometer',
                url: '/taiyuan/supervision',
              },
            ],
          },
        ],
      },
      {
        title: '同煤大厦',
        icon: 'fa fa-tachometer',
        key: 'sx-tm',
        url: 'javascript: void(0)',
        children: [
          {
            title: '电采暖',
            icon: 'fa fa-tachometer',
            key: 'bj-electric',
            url: '#',
            children: [],
          },
        ],
      },
      {
        title: '国电宿舍',
        icon: 'fa fa-tachometer',
        key: 'sx-gd',
        url: 'javascript: void(0)',
        children: [
          {
            title: '电采暖',
            icon: 'fa fa-tachometer',
            key: 'bj-electric',
            url: '#',
            children: [],
          },
        ],
      },
      {
        title: '知网大厦',
        icon: 'fa fa-tachometer',
        key: 'sx-zw',
        url: 'javascript: void(0)',
        children: [
          {
            title: '燃气采暖',
            icon: 'fa fa-tachometer',
            key: 'bj-electric',
            url: '#',
            children: [],
          },
        ],
      },
      {
        title: '太原市锦江之星',
        icon: 'fa fa-tachometer',
        key: 'sx-jj',
        url: 'javascript: void(0)',
        children: [
          {
            title: '燃气采暖',
            icon: 'fa fa-tachometer',
            key: 'bj-electric',
            url: '#',
            children: [],
          },
        ],
      },
      {
        title: '太原阳光国际商务中心',
        icon: 'fa fa-tachometer',
        key: 'sx-yggjsw',
        url: 'javascript: void(0)',
        children: [
          {
            title: '燃气采暖',
            icon: 'fa fa-tachometer',
            key: 'bj-electric',
            url: '#',
            children: [],
          },
        ],
      },
      {
        title: '运城恒泽大酒店',
        icon: 'fa fa-tachometer',
        key: 'sx-hzdjd',
        url: 'javascript: void(0)',
        children: [
          {
            title: '燃气采暖',
            icon: 'fa fa-tachometer',
            key: 'bj-electric',
            url: '#',
            children: [],
          },
        ],
      },
      {
        title: '山西臣功印刷包装有限公司',
        icon: 'fa fa-tachometer',
        key: 'sx-cgys',
        url: 'javascript: void(0)',
        children: [
          {
            title: '燃气采暖',
            icon: 'fa fa-tachometer',
            key: 'bj-electric',
            url: '#',
            children: [],
          },
        ],
      },
      {
        title: '御花园假日广场',
        icon: 'fa fa-tachometer',
        key: 'sx-yhyjrgc',
        url: 'javascript: void(0)',
        children: [
          {
            title: '燃气采暖',
            icon: 'fa fa-tachometer',
            key: 'bj-electric',
            url: '#',
            children: [],
          },
        ],
      },
    ],
  },
  {
    title: '北京项目',
    icon: 'fa fa-tachometer',
    key: 'sx',
    url: 'javascript: void(0)',
    children: [
      {
        title: '北京体育总局',
        icon: 'fa fa-tachometer',
        key: 'bj-tiyu',
        url: '#',
        children: [
          {
            title: '电采暖',
            icon: 'fa fa-tachometer',
            key: 'bj-tiyu-electric',
            url: '#',
            children: [],
          },
        ],
      },
      {
        title: '北京假日阳光别墅',
        icon: 'fa fa-tachometer',
        key: 'bj-ygbs',
        url: '#',
        children: [
          {
            title: '电采暖',
            icon: 'fa fa-tachometer',
            key: 'bj-ygbs-electric',
            url: '#',
            children: [],
          },
        ],
      },
    ],
  },
  {
    title: '东北项目',
    icon: 'fa fa-tachometer',
    key: 'db',
    url: 'javascript: void(0)',
    children: [
      {
        title: '哈尔滨人和名苑小区',
        icon: 'fa fa-tachometer',
        key: 'db-hebrh',
        url: '#',
        children: [
          {
            title: '电采暖',
            icon: 'fa fa-tachometer',
            key: 'db-hebrh-electric',
            url: '#',
            children: [],
          },
        ],
      },
      {
        title: '辽宁台安今夏小区',
        icon: 'fa fa-tachometer',
        key: 'db-lnta',
        url: '#',
        children: [
          {
            title: '电采暖',
            icon: 'fa fa-tachometer',
            key: 'db-lnta-electric',
            url: '#',
            children: [],
          },
        ],
      },
      {
        title: '沈阳新世纪家园别墅',
        icon: 'fa fa-tachometer',
        key: 'db-syxsj',
        url: '#',
        children: [
          {
            title: '电采暖',
            icon: 'fa fa-tachometer',
            key: 'db-syxsj-electric',
            url: '#',
            children: [],
          },
        ],
      },
    ],
  },
  {
    title: '其它导航',
    icon: 'fa',
    key: 'qita',
    url: '/other',
  },
]
