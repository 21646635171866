import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar'

import { TopbarComponent } from './Topbar/topbar.component'
import { TopbarLanguageSwitcherComponent } from './Topbar/LanguageSwitcher/language-switcher.component'
import { TopbarSearchComponent } from './Topbar/Search/search.component'
import { TopbarUserMenuComponent } from './Topbar/UserMenu/user-menu.component'
import { TopbarFavPagesComponent } from './Topbar/FavPages/fav-pages.component'
import { MenuLeftComponent } from './Menu/MenuLeft/menu-left.component'
import { MenuTopComponent } from './Menu/MenuTop/menu-top.component'
import { FooterComponent } from './Footer/footer.component'
import { BreadcrumbsComponent } from './Breadcrumbs/breadcrumbs.component'
import {
  NzAvatarModule,
  NzBadgeModule,
  NzDropDownModule,
  NzInputModule,
  NzLayoutModule,
  NzToolTipModule,
} from 'ng-zorro-antd'
import { SharedModule } from '@app/shared.module'

const COMPONENTS = [
  TopbarComponent,
  TopbarSearchComponent,
  TopbarUserMenuComponent,
  TopbarLanguageSwitcherComponent,
  MenuLeftComponent,
  MenuTopComponent,
  FooterComponent,
  BreadcrumbsComponent,
  TopbarFavPagesComponent,
]

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    NzInputModule,
    NzDropDownModule,
    NzToolTipModule,
    NzBadgeModule,
    NzAvatarModule,
    NzLayoutModule,
    SharedModule,
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class LayoutModule {}
