import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from 'src/app/shared.module'
import { LayoutsModule } from 'src/app/layouts/layouts.module'
import { AppPreloader } from 'src/app/app-routing-loader'
import { AuthGuard } from 'src/app/components/ui/system/Guard/auth.guard'
import { HomeComponent } from '../app/pages/auth/home/home.component'
// layouts & notfound
import { LayoutAuthComponent } from 'src/app/layouts/Auth/auth.component'
import { LayoutMainComponent } from 'src/app/layouts/Main/main.component'
import { DataHomeComponent } from '@app/pages/data-home/data-home.component'
import { LoginPageComponent } from '@app/pages/login-page/login-page.component'
import { SideFrameComponent } from '@components/side-frame/side-frame.component'
import { DatavWebglComponent } from '@app/pages/datav-webgl/datav-webgl.component'
import { TaiyuanCollegeOperatingComponent } from '@components/taiyuan-college-operating/taiyuan-college-operating.component'
import { TaiyuanCollegeSystemComponent } from '@components/taiyuan-college-system/taiyuan-college-system.component'
import { TaiyuanCollegeBuildComponent } from '@components/taiyuan-college-build/taiyuan-college-build.component'
import { TaiyuanTypeTimeSettingComponent } from '@components/taiyuan-type-time-setting/taiyuan-type-time-setting.component'
import { TaiyuanCollegeFloorComponent } from '@components/taiyuan-college-floor/taiyuan-college-floor.component'
import { TaiyuanCollegeTerminalComponent } from '@components/taiyuan-college-terminal/taiyuan-college-terminal.component'
import { TaiyuanCollegeModeComponent } from '@components/taiyuan-college-mode/taiyuan-college-mode.component'
import { TaiyuanCollegeLeafletComponent } from '@components/taiyuan-college-leaflet/taiyuan-college-leaflet.component'
import { TaiyuanCollegeHeapOperationComponent } from '@components/taiyuan-college-heap-operation/taiyuan-college-heap-operation.component'
import { TaiyuanCollegeSupervisionComponent } from '@components/taiyuan-college-supervision/taiyuan-college-supervision.component'

// @ts-ignore
const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'datav',
    component: DataHomeComponent,
    canActivate: [AuthGuard],
    data: { title: '可视化' },
  },
  {
    path: 'datav-bim',
    component: DatavWebglComponent,
    canActivate: [AuthGuard],
    data: { title: '可视化' },
  },
  {
    path: 'authenticated',
    component: LoginPageComponent,
    data: { title: '认证成功' },
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    component: HomeComponent,
  },
  {
    path: 'dashboard',
    component: LayoutMainComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('src/app/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
    ],
  },
  {
    path: 'taiyuan',
    component: LayoutMainComponent,
    children: [
      {
        path: 'operating',
        component: TaiyuanCollegeOperatingComponent,
        data: { title: '运营详情' },
      },
      {
        path: 'system',
        component: TaiyuanCollegeSystemComponent,
        data: { title: '系统设置' },
      },
      {
        path: 'build',
        component: TaiyuanCollegeBuildComponent,
        data: { title: '楼宇设置' },
      },
      {
        path: 'typetime',
        component: TaiyuanTypeTimeSettingComponent,
        data: { title: '类型及时间段设置' },
      },
      {
        path: 'mode',
        component: TaiyuanCollegeModeComponent,
        data: { title: '模式设置' },
      },
      {
        path: 'floor',
        component: TaiyuanCollegeFloorComponent,
        data: { title: '楼层设置' },
      },
      {
        path: 'terminal',
        component: TaiyuanCollegeTerminalComponent,
        data: { title: '温控器设置' },
      },
      {
        path: 'koperation',
        component: TaiyuanCollegeHeapOperationComponent,
        data: { title: '运营详情' },
      },
      {
        path: 'supervision',
        component: TaiyuanCollegeSupervisionComponent,
        data: { title: '热源监控' },
      },
    ],
  },
  {
    path: 'iframe',
    component: LayoutMainComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        component: SideFrameComponent,
      },
    ],
  },
  {
    path: 'auth',
    component: LayoutAuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/pages/auth/auth.module').then(m => m.AuthModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/auth/404',
  },
]

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: AppPreloader,
    }),
    LayoutsModule,
  ],
  providers: [AppPreloader],
  exports: [RouterModule],
})
export class AppRoutingModule {}
