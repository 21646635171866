import { Component, OnInit } from '@angular/core'
import { TemperatureChartService } from '@service/data-report/temperature-chart.service'

@Component({
  selector: 'app-temperature-chart',
  templateUrl: './temperature-chart.component.html',
  styleUrls: ['./temperature-chart.component.scss'],
})
export class TemperatureChartComponent implements OnInit {
  title: string = '项目温度监控表'
  constructor(private service: TemperatureChartService) {}
  chartOption: any
  ngOnInit(): void {
    this.service.getData().then(res => {
      this.chartOption = this.service.getOptions(res)
    })
  }
}
