import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl } from '@angular/forms'
import { TaiyuanCollegeOperatingService } from '@components/taiyuan-college-operating/taiyuan-college-operating.service'
import { CommonService } from '@app/common/CommonService'
import { convertRangeTemperature, convertRangeTime } from '@app/common/CommonUtil'
import { HttpParams } from '@angular/common/http'

@Component({
  selector: 'app-taiyuan-college-control',
  templateUrl: './taiyuan-college-operating.component.html',
  styleUrls: ['./taiyuan-college-operating.component.scss'],
})
export class TaiyuanCollegeOperatingComponent implements OnInit {
  columns = []
  data = []
  search = this.fb.group({
    sid: new FormControl(''),
    premisesId: new FormControl(''),
    floorId: new FormControl(''),
    modeName: new FormControl(''),
    periodType: new FormControl(''),
  })
  pagination = {
    pageNum: 1,
    pageSize: 10,
  }
  deviceVisible = false
  visibleStatus = false
  records = []
  sort = {}
  sortContainer = []
  totalCount = 0
  premises = []
  floors = []
  defaultPeriod = []
  temperatureType = []
  mapVisible = false
  leafLetData = []

  constructor(
    private fb: FormBuilder,
    private service: TaiyuanCollegeOperatingService,
    private commonService: CommonService,
  ) {}

  async ngOnInit() {
    this.columns = this.service.getColumns()
    this.sort = this.service.getSort()
    this.handleOnSearch()
    this.commonService.handleGetPremises().then(res => {
      this.premises = res.data.rows
    })
    this.handleGetTemperatureType()
    this.handleGetTemperatureMode()
  }

  handleOnSearch() {
    this.handleOnClearSort()
    this.service.handleOnSearch(this.search, this.pagination).then(res => {
      const data = res.data
      this.data = data.rows
      this.totalCount = data.totalNum
    })
  }
  handleOnReset() {
    this.service.handleOnReset(this.search)
  }
  handleOnPageIndexChange(item: number) {
    this.pagination.pageNum = item
    this.handleOnSearch()
  }
  handleOnPageSizeChange(item: number) {
    this.pagination.pageSize = item
    this.handleOnSearch()
  }
  handleOnGetRecord(item) {
    this.records = JSON.parse(item.operatingData)
    this.visibleStatus = true
  }
  handleOnSortChange(event) {
    let index = this.sortContainer.indexOf(event.key)
    console.log('column: ', this.columns)
    if (event.value) {
    }
  }
  handleOnClearSort() {
    this.columns.forEach(item => {
      item.sortOrder = null
    })
  }

  handleOnSearchPremiseSelectChange($event) {
    this.search.controls['floorId'].setValue('')
    this.search.controls['periodType'].setValue('')
    if (!$event) {
      this.floors = []
      this.defaultPeriod = []
    } else {
      this.handleGetFloors($event)
    }
  }

  handleOnSearchFloorSelectChange($event) {
    this.search.controls['periodType'].setValue('')
    if (!$event) {
      this.defaultPeriod = []
    } else {
    }
    this.handleGetTemperatureType()
    this.handleGetTemperatureMode()
  }

  handleGetTemperatureMode() {
    const params = {
      premisesId:
        this.search.controls['premisesId'].value == null
          ? ''
          : this.search.controls['premisesId'].value,
      floorId:
        this.search.controls['floorId'].value == null ? '' : this.search.controls['floorId'].value,
    }
    this.service.handleGetTemperatureMode(params).then(res => {
      this.defaultPeriod = res.data
    })
  }

  handleGetTemperatureType() {
    const params = {
      premisesId:
        this.search.controls['premisesId'].value == null
          ? ''
          : this.search.controls['premisesId'].value,
      floorId:
        this.search.controls['floorId'].value == null ? '' : this.search.controls['floorId'].value,
    }
    this.service.handleGetTemperatureType(params).then(res => {
      this.temperatureType = res.data
    })
  }

  handleGetFloors(premisesId: number) {
    this.search.controls['floorId'].setValue('')
    this.commonService.handleGetFloors(premisesId).then(res => {
      this.floors = res.data
    })
  }
  handleRangeTime(before, after) {
    return convertRangeTime(before, after)
  }
  handleRangeTemperature(down, up) {
    return convertRangeTemperature(down, up)
  }
}
