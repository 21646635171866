import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-data-home-head',
  templateUrl: './data-home-head.component.html',
  styleUrls: ['./data-home-head.component.scss'],
})
export class DataHomeHeadComponent implements OnInit {
  constructor() {}
  title: string = '瑞海热控指挥能源云实时数据看板'
  ngOnInit(): void {}
}
