import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {
  INSERT_FLOORS,
  NO_INSERT_FLOORS,
  QUERY_FLOORS,
  SWITCH_FLOORS,
  UPDATE_FLOORS,
} from '@app/common/TaidaApi'
import { AliCloudResponse, UnifyResponse } from '@app/common/AliCloudResponse'

@Injectable({
  providedIn: 'root',
})
export class TaiyuanCollegeFloorService {
  constructor(private http: HttpClient) {}
  getColumn() {
    return [
      {
        key: 'floorId',
        title: '楼层ID',
      },
      {
        key: 'floorName',
        title: '楼层名称',
      },
      {
        key: 'groupCount',
        title: '分组数量',
      },
      {
        key: 'modeCount',
        title: '模式数量',
      },
      {
        key: 'typeCount',
        title: '类型数量',
      },
    ]
  }
  getData(data) {
    return this.http.get<any[]>(QUERY_FLOORS + `/${data}`).toPromise()
  }

  getMoods() {
    return [
      {
        id: 1,
        name: '局域网',
      },
      {
        id: 2,
        name: '云端接入',
      },
    ]
  }

  handleGetFloors(id: number) {
    return this.http.get<Array<number>>(NO_INSERT_FLOORS + `/${id}`).toPromise()
  }

  handleUpdate(data) {
    data.pid = data.premisesId
    data.fid = data.floorId
    delete data.premisesId
    delete data.modeCount
    return this.http
      .post<UnifyResponse>(UPDATE_FLOORS + `?pid=${data.pid}&fid=${data.floorId}`, data)
      .toPromise()
  }

  handleInsert(data) {
    const premisesId = data.premisesId
    delete data.premisesId
    delete data.modeCount
    // data.isDisabled = '0'
    // data.isShow = '1'
    return this.http.put<number>(INSERT_FLOORS + `/${premisesId}`, data).toPromise()
  }

  handleSwitch(param: { fid: any; pid: any; isDisabled: string }) {
    return this.http.post(SWITCH_FLOORS, null, { params: param }).toPromise()
  }

  getUpdateId(premisesId) {
    const url =
      'https://c3e35869d12944cbba6b8ba19f5b2182-cn-beijing.alicloudapi.com/rhthermo/eheating/tyxy/entity/floor?appCode=3cbf3469059b491a91c7f2a43889d381'
    const param = { premisesId }
    return this.http
      .get<AliCloudResponse>(url, { params: param })
      .toPromise()
  }
}
