<div class="container-wrapper">
  <div class="bg-container"></div>
  <div class="content-wrapper">
    <div class="search-wrapper">
      <div class="form-item">
        <span>楼宇：</span>
        <nz-select
          [(ngModel)]="search.premisesId"
          (ngModelChange)="handleOnPremiseSelectChange($event)"
        >
          <nz-option
            *ngFor="let item of premised"
            [nzValue]="item.premisesId"
            [nzLabel]="item.premisesName"
          ></nz-option>
        </nz-select>
      </div>
      <div class="form-item">
        <span>楼层：</span>
        <nz-select [(ngModel)]="search.floorId">
          <nz-option
            *ngFor="let item of floors"
            [nzValue]="item.floorId"
            [nzLabel]="item.floorName"
          ></nz-option>
        </nz-select>
      </div>
      <div class="operate">
        <nz-button-group>
          <button class="rhthermo-button-hover" nz-button (click)="handleGetData()">查询</button>
          <button class="rhthermo-button-hover" (click)="handleOnOpen(null)" nz-button>添加</button>
        </nz-button-group>
      </div>
    </div>
    <div class="table-wrapper">
      <nz-table class="table-wrapper-item" #basicTable1 [nzData]="data" [nzShowPagination]="false">
        <thead>
          <tr>
            <th *ngFor="let column of columns" nzAlign="center">
              <span>{{ column.title }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable1.data">
            <td nzAlign="center">{{ data.periodTypeId }}</td>
            <td nzAlign="center">{{ data.periodType }}</td>
            <td nzAlign="center">{{ data.periodTypeSegs }}</td>
            <td nzAlign="center" class="pointer">
              <button
                nz-button
                class="rhthermo-table-text-button"
                (click)="handleGetChildData(data.segment)"
              >
                查看
              </button>
            </td>
            <td nzAlign="center" class="pointer">
              <button nz-button class="rhthermo-table-text-button" (click)="handleOnOpen(data)">
                修改
              </button>
            </td>
            <td nzAlign="center">
              <nz-switch
                class="rhthermo-switch"
                [(ngModel)]="data.disabled"
                nzCheckedChildren="1"
                nzUnCheckedChildren="0"
                nzCheckedChildren="开"
                nzUnCheckedChildren="关"
                nzControl="true"
                (click)="handleOnSwitch(data)"
              ></nz-switch>
            </td>
            <td nzAlign="center" class="pointer">
              <div
                nz-popconfirm
                nzPopconfirmTitle="确定要删除吗？"
                (nzOnConfirm)="handleOnDelete(data)"
              >
                <button nz-button class="rhthermo-table-text-button">
                  删除
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </nz-table>
      <nz-table
        *ngIf="childVisible"
        class="table-wrapper-item"
        #basicTable2
        [nzData]="childData"
        [nzShowPagination]="false"
      >
        <thead>
          <tr>
            <th *ngFor="let column of childColumns" nzAlign="center">
              <span>{{ column.title }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable2.data">
            <td nzAlign="center">{{ data.periodTypeId }}</td>
            <td nzAlign="center">{{ data.periodType }}</td>
            <td nzAlign="center">{{ data.periodSegId }}</td>
            <td nzAlign="center">{{ data.periodBeginTime }}</td>
            <td nzAlign="center">
              {{ data.periodTempDown | temperature: data.periodTempUp }}
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
  <nz-modal
    [(nzVisible)]="modalVisible"
    nzClassName="modal-wrapper"
    [nzTitle]="modalTitle + '类型段信息'"
    (nzOnCancel)="handleOnCloseModal()"
    (nzOnOk)="handleOnUpdate()"
  >
    <ng-container>
      <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOnUpdate()">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="premisesId">楼宇</nz-form-label>
          <nz-form-control>
            <nz-select formControlName="premisesId" class="middle-width">
              <nz-option
                *ngFor="let item of premised"
                [nzValue]="item.premisesId"
                [nzLabel]="item.premisesName"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired nzFor="floorId">楼层</nz-form-label>
          <nz-form-control>
            <nz-select formControlName="floorId" class="middle-width">
              <nz-option
                *ngFor="let item of floors"
                [nzValue]="item.floorId"
                [nzLabel]="item.floorName"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired class="middle-width" nzFor="periodTypeId"
            >温控类型ID</nz-form-label
          >
          <nz-form-control> <input nz-input formControlName="periodTypeId"/></nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired class="middle-width" nzFor="periodType">类型名称</nz-form-label>
          <nz-form-control>
            <input nz-input formControlName="periodType" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired class="middle-width" nzFor="periodTypeSegs"
            >类型段数量</nz-form-label
          >
          <nz-form-control>
            <input nz-input formControlName="periodTypeSegs" />
          </nz-form-control>
        </nz-form-item>
        <div formArrayName="typeChild">
          <div
            class="block-wrapper"
            *ngFor="let child of validateForm.controls['typeChild']['controls']; let i = index"
          >
            <nz-form-item class="title-wrapper">
              <p class="title-item">类型段 {{ i + 1 }} 设置</p>
            </nz-form-item>
            <nz-form-item [formGroupName]="i">
              <nz-form-label>开始时间</nz-form-label>
              <nz-form-control>
                <nz-time-picker formControlName="periodBeginTime" nzFormat="HH:mm"></nz-time-picker>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item [formGroupName]="i">
              <nz-form-label>温度上下限</nz-form-label>
              <nz-form-control>
                <nz-input-number formControlName="periodTempDown"></nz-input-number>
              </nz-form-control>
              <span class="separator"> - </span>
              <nz-form-control>
                <nz-input-number formControlName="periodTempUp"></nz-input-number>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
    </ng-container>
  </nz-modal>
</div>
