<iframe
  class="iframe-wrapper"
  [src]="path"
  width="100%"
  sandbox="allow-same-origin allow-top-navigation allow-forms allow-scripts allow-popups"
  seamless
  scrolling="yes"
  height="calc(100vh - 155px)"
  frameborder="0"
></iframe>
