import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { TYPE_LIST } from '@app/common/TaidaApi'

@Injectable({
  providedIn: 'root',
})
export class TaiyuanTypeTimeSettingService {
  constructor(private http: HttpClient) {}
  getColumns() {
    return [
      {
        key: 'typeId',
        title: '类型ID',
      },
      {
        key: 'typeName',
        title: '类型名称',
      },
      {
        key: 'typeCount',
        title: '类型段数量',
      },
      {
        key: 'typeInformation',
        title: '类型段信息',
      },
      {
        key: 'modify',
        title: '修改',
      },
      {
        key: 'delete',
        title: '启停',
      },
      {
        title: '删除类型段',
      },
    ]
  }

  getChildColumns() {
    return [
      {
        key: 'typeId',
        title: '类型ID',
      },
      {
        key: 'typeName',
        title: '类型名称',
      },
      {
        key: 'typeSectionId',
        title: '类型段ID',
      },
      {
        key: 'startTime',
        title: '起始时间',
      },
      {
        key: 'temperature',
        title: '类型段温度上下限',
      },
    ]
  }

  getAsideColumns() {
    return [
      {
        key: 'typeId',
        title: '类型ID',
      },
      {
        key: 'typeName',
        title: '类型名称',
      },
      {
        key: 'typeChildId',
        title: '类型段ID',
      },
      {
        key: 'startTime',
        title: '起始时间',
      },
      {
        key: 'temperature',
        title: '类型段温度上下限',
      },
    ]
  }

  getData(param): Promise<string> {
    const { premisesId, floorId } = param
    return this.http
      .get<string>(TYPE_LIST, { params: { pid: premisesId, fid: floorId } })
      .toPromise()
  }

  handleDelete(data: object) {}

  handleSwitch(param: { fid: any; pid: string; isDisabled: string }) {
    return new Promise((resolve, reject) => {})
  }
}
