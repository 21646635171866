import { Component, OnInit } from '@angular/core'
import { TaiyuanCollegeModeService } from '@components/taiyuan-college-mode/taiyuan-college-mode.service'
import { CommonService } from '@app/common/CommonService'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'

@Component({
  selector: 'app-taiyuan-college-mode',
  templateUrl: './taiyuan-college-mode.component.html',
  styleUrls: ['./taiyuan-college-mode.component.scss'],
})
export class TaiyuanCollegeModeComponent implements OnInit {
  columns: Array<any> = []
  premises: Array<any> = []
  floors: Array<any> = []
  data: any[] = []

  search: FormGroup
  modalVisible: boolean = false
  validateForm: FormGroup = this.formBuilder.group({
    premisesId: [{ value: null, disabled: true }, [Validators.required]],
    floorId: [{ value: null, disabled: true }, [Validators.required]],
    modeId: [{ value: null, disabled: true }, [Validators.required]],
    modeName: [{ value: null, disabled: true }, [Validators.required]],
    tempDownlimits: [null, [Validators.required]],
    tempUplimits: [null, [Validators.required, Validators.min(1), Validators.max(100)]],
  })

  constructor(
    private service: TaiyuanCollegeModeService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.handleGetColumns()
    this.handleInitSearchFormGroup()
    this.handleGetPremises()
  }

  handleGetPremises() {
    this.commonService.handleGetPremises().then(res => {
      this.premises = res.data.rows
      if (this.premises.length > 0) {
        const premisesId = this.premises[0].premisesId
        this.search.controls['premisesId'].setValue(premisesId)
        this.handleGetFloors()
      }
    })
  }

  handleGetFloors() {
    this.commonService.handleGetFloors(this.search.controls['premisesId'].value).then(res => {
      this.floors = res.data
      if (this.floors.length > 0) {
        this.search.controls['floorId'].setValue(this.floors[0].floorId)
        this.handleGetData()
      }
    })
  }

  handleGetColumns() {
    this.columns = this.service.getColumns()
  }

  handleInitSearchFormGroup() {
    this.search = this.formBuilder.group({
      premisesId: new FormControl(null),
      floorId: new FormControl(null),
    })
  }

  handleOnSearchPremiseSelectChange(value) {
    this.search.controls['floorId'].setValue('')
    this.handleGetFloors()
  }

  handleGetData() {
    this.service.handleGetData(this.search.value).then(res => {
      this.data = res
    })
  }

  handleOnUpdate() {
    this.service.handleModeModify(this.validateForm.getRawValue()).then(res => {
      this.modalVisible = false
      this.handleGetData()
    })
  }

  handleOnOpen(data) {
    let params = Object.assign(this.search.value, data)
    this.validateForm.patchValue(params)
    this.modalVisible = true
  }

  handleCloseModal() {
    this.modalVisible = false
  }
}
