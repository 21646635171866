import { Component, OnInit, AfterViewInit } from '@angular/core'
import * as L7 from '@antv/l7'
import * as l7m from '@antv/l7-maps'

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, AfterViewInit {
  products = [
    '太原学院',
    '同煤大厦',
    '国电宿舍',
    '知网大厦',
    '太原市锦江之星',
    '太原阳光国际商务中心',
    '运城恒泽大酒店',
    '山西臣功印刷包装有限公司',
    '御花园假日广场',
    '北京体育总局',
    '北京假日阳光别墅',
    '哈尔滨人和名苑小区',
    '黑龙江萝北富元小区',
    '辽宁台安金夏小区',
    '沈阳世纪家园别墅',
  ]
  ngOnInit() {}

  ngAfterViewInit() {
    console.log('l7 scene: ', L7.Scene)
    const data = [
      { name: '太原学院', Latitude: 37.696135, Longitude: 112.601364, picture: '太原学院.jpeg' },
      { name: '同煤大厦', Latitude: 40.03896064, Longitude: 113.179212, picture: '同煤大厦.jpeg' },
      { name: '国电宿舍', Latitude: 37.87228782, Longitude: 112.570053, picture: '国电宿舍.jpeg' },
      { name: '知网大厦', Latitude: 37.86388978, Longitude: 112.558614, picture: '知网大厦.jpeg' },
      {
        name: '太原市锦江之星',
        Latitude: 37.86010577,
        Longitude: 112.571885,
        picture: '太原市锦江之星.jpeg',
      },
      {
        name: '太原阳光国际商务中心',
        Latitude: 37.82873738,
        Longitude: 112.559778,
        picture: '太原阳光国际商务中心.jpeg',
      },
      {
        name: '运城恒泽大酒店',
        Latitude: 35.03627005,
        Longitude: 111.023445,
        picture: '运城恒泽大酒店.jpeg',
      },
      {
        name: '山西臣功印刷包装有限公司',
        Latitude: 37.73336086,
        Longitude: 112.584367,
        picture: '山西臣功印刷包装有限公司.jpeg',
      },
      {
        name: '御花园假日广场',
        Latitude: 37.87594425,
        Longitude: 112.567876,
        picture: '御花园假日广场.jpeg',
      },
      {
        name: '北京体育总局',
        Latitude: 39.85760681,
        Longitude: 116.414636,
        picture: '北京体育总局.jpeg',
      },
      {
        name: '北京假日阳光别墅',
        Latitude: 40.37875076,
        Longitude: 116.03353,
        picture: '北京假日阳光别墅.jpeg',
      },
      {
        name: '哈尔滨人和名苑小区',
        Latitude: 45.76715569,
        Longitude: 126.676414,
        picture: '哈尔滨人和名苑小区.jpeg',
      },
      {
        name: '黑龙江萝北富元小区',
        Latitude: 47.60082441,
        Longitude: 130.890242,
        picture: '黑龙江萝北富元小区.jpeg',
      },
      {
        name: '辽宁台安金夏小区',
        Latitude: 41.39115976,
        Longitude: 122.437133,
        picture: '辽宁台安金夏小区.jpeg',
      },
      {
        name: '沈阳新世纪家园别墅',
        Latitude: 41.77099445,
        Longitude: 123.48192,
        picture: '沈阳新世纪家园别墅.jpeg',
      },
    ]
    data.forEach(item => {
      item.picture = `assets/img/map/${item.picture}`
    })
    const scene = new L7.Scene({
      logoVisible: false,
      id: 'map',
      map: new l7m.GaodeMap({
        pitch: 0,
        style: 'dark',
        center: [105, 35],
        zoom: 4.5,
      }),
    })
    // fetch('https://gw.alipayobjects.com/os/basement_prod/9078fd36-ce8d-4ee2-91bc-605db8315fdf.csv')
    //   .then(res => res.text())
    //   .then(data => {
    //     const pointLayer = new L7.PointLayer({})
    //       .source(data, {
    //         parser: {
    //           type: 'csv',
    //           x: 'Longitude',
    //           y: 'Latitude',
    //         },
    //       })
    //       .shape('circle')
    //       .active(true)
    //       .animate(true)
    //       .size(40)
    //       .color('#78b912')
    //       .style({
    //         opacity: 1,
    //       })
    //     pointLayer.on('mousemove', e => {
    //       const popup = new L7.Popup({
    //         offsets: [0, 0],
    //         closeButton: false,
    //       }).setLnglat(e.lngLat).setHTML(`
    //                 <div class="popup">
    //                     <h6><a href="e.feature.FullName">${e.feature.FullName}</a></h6>
    //                     <img src="https://rhthermo.com/static/media/school.4b56d3d6.png" alt="" width="100%" height="100%">
    //                     <h6>Longitude:${e.feature.Longitude}</h6>
    //                     <h6>Latitude:${e.feature.Latitude}</h6>
    //                 </div>
    //         `)
    //       scene.addPopup(popup)
    //     })
    //     scene.addLayer(pointLayer)
    //     const overlayers = {
    //       低能耗运转: pointLayer,
    //     }
    //     const layersControl = new L7.Layers({
    //       overlayers,
    //     })
    //     // 添加 Filter Control
    //     scene.addControl(layersControl)
    //   })
    console.log('data: ', data)
    const pointLayer = new L7.PointLayer({})
      .source(data, {
        parser: {
          type: 'json',
          x: 'Longitude',
          y: 'Latitude',
        },
      })
      .shape('circle')
      .active(true)
      .animate(true)
      .size(40)
      .color('#78b912')
      .style({
        opacity: 1,
      })
    pointLayer.on('mousemove', e => {
      const popup = new L7.Popup({
        offsets: [0, 0],
        closeButton: false,
      }).setLnglat(e.lngLat).setHTML(`
                    <div class="popup">
                        <h6><a href="#">${e.feature.name}</a></h6>
                        <img src="${e.feature.picture}" alt="" width="100%" height="100%">
                    </div>
            `)
      scene.addPopup(popup)
    })
    scene.addLayer(pointLayer)
    const overlayers = {
      低能耗运转: pointLayer,
    }
    const layersControl = new L7.Layers({
      overlayers,
    })
    // 添加 Filter Control
    scene.addControl(layersControl)
    const zoomControl = new L7.Zoom({
      position: 'topright',
    })
    // 添加 Zoom Control
    scene.addControl(zoomControl)

    const scaleControl = new L7.Scale({
      position: 'bottomright',
    })
    // 添加 Scale Control
    scene.addControl(scaleControl)
  }
}
