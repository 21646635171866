import { NgModule } from '@angular/core'
import { TaiyuanCollegeModeComponent } from '@components/taiyuan-college-mode/taiyuan-college-mode.component'
import { ReactiveFormsModule } from '@angular/forms'
import {
  NzButtonModule,
  NzFormModule,
  NzModalModule,
  NzSelectModule,
  NzTableModule,
} from 'ng-zorro-antd'
import { CommonModule } from '@angular/common'
import { AntdModule } from '@app/antd.module'
import { PipeModule } from '@app/pipes/pipe-module.modules'

@NgModule({
  declarations: [TaiyuanCollegeModeComponent],
  exports: [TaiyuanCollegeModeComponent],
  imports: [
    ReactiveFormsModule,
    NzFormModule,
    NzSelectModule,
    CommonModule,
    NzTableModule,
    NzModalModule,
    NzButtonModule,
    AntdModule,
    PipeModule,
  ],
})
export class TaiyuanCollegeModeModule {}
