import { Component, OnInit } from '@angular/core'
import { TaiyuanCollegeSystemService } from '@components/taiyuan-college-system/taiyuan-college-system.service'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { HttpClient } from '@angular/common/http'
import { SETTING_CONFIGURATION_QUERY, SETTING_CONFIGURATION_UPDATE } from '@app/common/TaidaApi'

@Component({
  selector: 'app-taiyuan-college-system',
  templateUrl: './taiyuan-college-system.component.html',
  styleUrls: ['./taiyuan-college-system.component.scss'],
})
export class TaiyuanCollegeSystemComponent implements OnInit {
  constructor(
    private service: TaiyuanCollegeSystemService,
    private fb: FormBuilder,
    private http: HttpClient,
  ) {}

  systemForm!: FormGroup

  handleOnInitForm() {
    this.http.get<object>(SETTING_CONFIGURATION_QUERY).subscribe(res => {
      console.log('res: ', res)
      this.systemForm.patchValue(res)
    })
  }

  ngOnInit(): void {
    this.handleInitSetting()
    this.handleOnInitForm()
  }

  private copyObject(obj: Object) {
    return JSON.parse(JSON.stringify(obj))
  }

  handleOnCancel() {
    this.handleOnInitForm()
  }

  handleOnSave() {
    for (const item in this.systemForm.controls) {
      if (this.systemForm.controls.hasOwnProperty(item)) {
        this.systemForm.controls[item].markAsDirty()
        this.systemForm.controls[item].updateValueAndValidity()
      }
    }
    console.log('form value: ', this.systemForm)
    if (this.systemForm.valid) {
      this.http.post(SETTING_CONFIGURATION_UPDATE, this.systemForm.value).subscribe(res => {
        this.systemForm.patchValue(res)
      })
    } else {
      console.log('checked false')
    }
  }

  handleInitSetting() {
    this.systemForm = this.fb.group(
      {
        databaseIP: new FormControl('', Validators.required),
        localIP: new FormControl('', [
          Validators.pattern(
            /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/,
          ),
          Validators.required,
        ]),
        localPort: new FormControl(1, [
          Validators.required,
          Validators.min(1),
          Validators.max(65533),
        ]),
        comPort: new FormControl(1, [
          Validators.required,
          Validators.min(1),
          Validators.max(65533),
        ]),
        comFormat: new FormControl('', Validators.required),
        mqttIP: new FormControl('', [
          Validators.pattern(
            /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/,
          ),
          Validators.required,
        ]),
        mqttPort: new FormControl(1, [
          Validators.required,
          Validators.min(1),
          Validators.max(65533),
        ]),
      },
      { updateOn: 'blur' },
    )
  }
}
