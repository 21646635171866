<div class="main-container">
  <div class="bg-container"></div>
  <div class="wrap-container">
    <div class="webgl">
      <canvas id="unityContainer"></canvas>
    </div>
    <div class="content-container">
      <div class="title-container">
        <span class="title">空气源热泵设备参数</span>
      </div>
      <div class="params-container">
        <div class="title">
          <span>设备基础参数</span>
        </div>
        <div class="param-items">
          <div class="param-item">
            <div class="param-content">
              <span>设备型号</span>
            </div>
            <div class="param-value">
              <span>RH-QHTF-21D机组</span>
            </div>
          </div>
          <div class="param-item">
            <div class="param-content">
              <span>设备类型</span>
            </div>
            <div class="param-value">
              <span>空气源热泵</span>
            </div>
          </div>
          <div class="param-item">
            <div class="param-content">
              <span>设备厂商</span>
            </div>
            <div class="param-value">
              <span>清华同方</span>
            </div>
          </div>
          <div class="param-item">
            <div class="param-content">
              <span>额定制热量</span>
            </div>
            <div class="param-value">
              <span>40 kW</span>
            </div>
          </div>
          <div class="param-item">
            <div class="param-content">
              <span>额定功率</span>
            </div>
            <div class="param-value">
              <span>6.5 kW</span>
            </div>
          </div>
          <div class="param-item">
            <div class="param-content">
              <span>额定电流</span>
            </div>
            <div class="param-value">
              <span>8.5 A</span>
            </div>
          </div>
          <div class="param-item">
            <div class="param-content">
              <span>电源</span>
            </div>
            <div class="param-value">
              <span>380/50 、220 V/Hz</span>
            </div>
          </div>
          <div class="param-item">
            <div class="param-content">
              <span>介质温度</span>
            </div>
            <div class="param-value">
              <span>-18° ~ +75°</span>
            </div>
          </div>
        </div>
      </div>
      <div class="params-container">
        <div class="title">
          <span>设备实时运营参数</span>
        </div>
        <div class="param-items">
          <div class="param-item">
            <div class="param-content">
              <span>工作状态</span>
            </div>
            <div class="param-value">
              <span>开启</span>
            </div>
          </div>
          <div class="param-item">
            <div class="param-content">
              <span>出水温度</span>
            </div>
            <div class="param-value">
              <span>51 °</span>
            </div>
          </div>
          <div class="param-item">
            <div class="param-content">
              <span>回水温度</span>
            </div>
            <div class="param-value">
              <span>31 °</span>
            </div>
          </div>
          <div class="param-item">
            <div class="param-content">
              <span>压力监控</span>
            </div>
            <div class="param-value">
              <span>2.1 Mpa</span>
            </div>
          </div>
          <div class="param-item">
            <div class="param-content">
              <span>水源循环流量：</span>
            </div>
            <div class="param-value">
              <span>6.5 m³/h</span>
            </div>
          </div>
          <div class="param-item">
            <div class="param-content">
              <span>补水状态</span>
            </div>
            <div class="param-value">
              <span>正在补水</span>
            </div>
          </div>
          <div class="param-item">
            <div class="param-content">
              <span>补水量</span>
            </div>
            <div class="param-value">
              <span>3.2 m³</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
