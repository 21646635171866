import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-datav-webgl',
  templateUrl: './datav-webgl.component.html',
  styleUrls: ['./datav-webgl.component.scss'],
})
export class DatavWebglComponent implements OnInit {
  // url = require('../../../assets/unity-sources/academy/Build/RH_WEBGL.json')
  url = '../../../assets/unity-sources/academy/Build/RH_WEBGL.json'

  constructor() {}

  ngOnInit(): void {}
}
