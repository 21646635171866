import { Component, OnInit } from '@angular/core'
import { TaiyuanCollegeFloorService } from '@components/taiyuan-college-floor/taiyuan-college-floor.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { CommonService } from '@app/common/CommonService'
import { NzModalService, NzNotificationService } from 'ng-zorro-antd'
import { map } from 'rxjs/operators'

@Component({
  selector: 'app-taiyuan-college-floor',
  templateUrl: './taiyuan-college-floor.component.html',
  styleUrls: ['./taiyuan-college-floor.component.scss'],
})
export class TaiyuanCollegeFloorComponent implements OnInit {
  data: Array<any> = []
  columns: Array<any> = []
  search = {
    premisesId: null,
  }
  premises = []
  validateForm!: FormGroup
  modalVisible: boolean = false
  title: String = '添加'
  isUpdate: boolean = false
  floors: Array<any> = []

  constructor(
    private service: TaiyuanCollegeFloorService,
    private fb: FormBuilder,
    private commonService: CommonService,
    private nzNotification: NzNotificationService,
    private modal: NzModalService,
  ) {}

  ngOnInit(): void {
    this.columns = this.service.getColumn()
    this.commonService.handleGetPremises().then(res => {
      this.premises = res.data.rows
      if (this.premises && this.premises.length > 0) {
        this.search.premisesId = this.premises[0].premisesId
        this.handleGetData()
        this.handleGetFloors()
      }
    })
    this.handleInitForm()
  }

  handleOnDelete() {}

  handleInitForm() {
    this.validateForm = this.fb.group({
      premisesId: [{ value: null, disabled: true }, [Validators.required]],
      floorId: [null, [Validators.required]],
      floorName: [null, [Validators.required]],
      terminalCount: [null, [Validators.required, Validators.min(1), Validators.max(40)]],
      modeCount: [{ value: 5, disabled: true }],
      typeCount: [null, [Validators.required]],
    })
  }

  handleOnUpdate() {
    for (const item in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(item)) {
        this.validateForm.controls[item].markAsDirty()
        this.validateForm.controls[item].updateValueAndValidity()
      }
    }
    if (this.validateForm.valid) {
      if (this.isUpdate) {
        // 更新
        this.service.handleUpdate(this.validateForm.getRawValue()).then(res => {
          this.modalVisible = false
          this.handleGetData()
          this.handleGetFloors()
        })
      } else {
        // 添加
        this.service.handleInsert(this.validateForm.getRawValue()).then(res => {
          if (res !== 200) {
            let message = '该楼层已被添加，可以前去修改。'
            this.service.handleGetFloors(this.search.premisesId).then(res => {
              let len = res.length
              message += len > 0 ? '或添加别的楼层。' : '所有楼层添加完毕。'
              if (len > 0) {
                this.floors = res
              } else {
                this.floors = []
              }
              this.nzNotification.error('错误', message)
            })
          }
          this.handleGetFloors()
          this.handleGetData()
          this.validateForm.reset()
          this.modalVisible = false
        })
      }
      console.log('验证成功')
    } else {
      console.log('checked false')
    }
  }
  handleOnCancel() {
    this.handleGetFloors()
    this.modalVisible = false
    this.validateForm.reset()
  }

  handleGetData() {
    this.service.getData(this.search.premisesId).then(res => {
      this.data = res
      this.data.forEach(item => (item.disabled = item.isDisabled === '1' ? false : true))
    })
  }

  handleGetFloors() {
    this.service.handleGetFloors(this.search.premisesId).then(res => {
      this.floors = res
    })
  }

  handleOnOpen(data) {
    this.service.getUpdateId(this.search.premisesId).then(res => {
      console.log('data: ', res.data)
      this.floors = res.data.map(item => item.floorId)
    })
    this.modalVisible = true
    const premise = this.premises.filter(item => item.premisesId === this.search.premisesId)[0]
    this.validateForm.controls['modeCount'].setValue(5)
    this.validateForm.patchValue(premise)
    if (!data) {
      this.title = '添加'
      this.isUpdate = false
      return
    }
    this.title = '修改'
    this.isUpdate = true
    this.validateForm.patchValue(data)
    console.log('form value: ', this.validateForm.getRawValue())
  }

  handleOnSwitch(data) {
    const title = data.disabled ? '关停' : '开启'
    const content = data.disabled ? '确定要关闭吗？' : '确定重新开启此楼吗？'
    const isDisabled = data.disabled ? '1' : '0'
    this.modal.confirm({
      nzTitle: `<i>${title}</i>`,
      nzContent: `${content}`,
      nzOnOk: () => {
        this.service
          .handleSwitch({ pid: this.search.premisesId, fid: data.floorId, isDisabled: isDisabled })
          .then(() => this.handleGetData())
      },
    })
  }

  handleOnDeleteForConfirm(data) {}
}
