import { AliCloudResponse, appCode, prefix_url } from '@app/common/AliCloudResponse'
import {
  COLLEGE_FLOOR,
  COLLEGE_PERIOD_TYPE,
  COLLEGE_PREMISES,
  COLLEGE_TERMINAL,
} from '@app/common/TaidaApi'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private http: HttpClient) {}
  // 查询楼宇
  handleGetPremises() {
    const url = `${prefix_url}${COLLEGE_PREMISES}`
    const params = new HttpParams()
      .set('appCode', appCode)
      .set('pageNum', '1')
      .set('pageSize', '100')
    return this.http
      .get<AliCloudResponse>(url, { params })
      .toPromise()
  }
  // 查询楼层
  handleGetFloors(premisesId: number) {
    const url = `${prefix_url}${COLLEGE_FLOOR}`
    const params = new HttpParams().set('appCode', appCode).set('premisesId', '' + premisesId)
    return this.http
      .get<AliCloudResponse>(url, { params })
      .toPromise()
  }
  // 查询供热类型
  handleGetDefaultPeriodType($event: string, premisesName = '') {
    const url = `${prefix_url}${COLLEGE_PERIOD_TYPE}`
    const params = new HttpParams()
      .set('appCode', appCode)
      .set('floorName', $event)
      .set('premisesname', premisesName)
    return this.http
      .get<AliCloudResponse>(url, { params })
      .toPromise()
  }
  // 查询终端
  handleGetDevice(premisesName, floorName) {
    const url = `${prefix_url}${COLLEGE_TERMINAL}`
    const params = new HttpParams()
      .set('appCode', appCode)
      .set('premisesName', premisesName)
      .set('floorName', floorName)
    return this.http
      .get<AliCloudResponse>(url, { params })
      .toPromise()
  }
}
