<div class="container-wrapper">
  <div class="bg-container"></div>
  <div class="content-wrapper">
    <div class="search-wrapper">
      <div class="form-item">
        <span>楼宇：</span>
        <nz-select
          [(ngModel)]="search.premisesId"
          (ngModelChange)="handleOnPremiseSelectChange($event)"
        >
          <nz-option
            *ngFor="let item of premises"
            [nzValue]="item.premisesId"
            [nzLabel]="item.premisesName"
          ></nz-option>
        </nz-select>
      </div>
      <div class="form-item">
        <span>楼层：</span>
        <nz-select
          [(ngModel)]="search.floorId"
          (ngModelChange)="handleOnFloorsSelectChange($event)"
        >
          <nz-option
            *ngFor="let item of floors"
            [nzValue]="item.floorId"
            [nzLabel]="item.floorName"
          ></nz-option>
        </nz-select>
      </div>
      <div class="operate">
        <nz-button-group>
          <button class="rhthermo-button-hover" nz-button (click)="handleGetData()">查询</button>
          <button class="rhthermo-button-hover" (click)="handleOpenModal(null)" nz-button>
            添加
          </button>
        </nz-button-group>
      </div>
    </div>
    <div class="table-wrapper">
      <nz-table #basicTable [nzData]="data" [nzShowPagination]="false">
        <thead>
          <tr>
            <th *ngFor="let column of columns" [nzWidth]="column.width" nzAlign="center">
              <span>{{ column.title }}</span>
            </th>
            <th nzAlign="center" class="pointer">整体连接测试</th>
            <th nzAlign="center">修改属性</th>
            <th nzAlign="center">终端启停</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td nzAlign="center">{{ data.terminalId }}</td>
            <td nzAlign="center">{{ data.terminalPosition }}</td>
            <td nzAlign="center">{{ data.modeId }}</td>
            <td nzAlign="center">{{ data.modeName }}</td>
            <td nzAlign="center">{{ data.periodTypeId }}</td>
            <td nzAlign="center">{{ data.periodType }}</td>
            <td nzAlign="center">
              {{ data.tempDownLogical | temperature: data.tempUpLogical }}
            </td>
            <td nzAlign="center">{{ data.tempComp | temperature }}</td>
            <td nzAlign="center" class="pointer">
              <button nz-button class="rhthermo-table-text-button">
                测试
              </button>
            </td>
            <td nzAlign="center" class="pointer">
              <button nz-button class="rhthermo-table-text-button" (click)="handleOpenModal(data)">
                修改
              </button>
            </td>
            <td nzAlign="center">
              <nz-switch
                class="rhthermo-switch"
                [(ngModel)]="data.disabled"
                nzCheckedChildren="1"
                nzUnCheckedChildren="0"
                nzCheckedChildren="开"
                nzUnCheckedChildren="关"
                nzControl="true"
                (click)="handleOnSwitch(data)"
              ></nz-switch>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
  <nz-modal
    [(nzVisible)]="modalVisible"
    nzClassName="modal-wrapper"
    [nzTitle]="modalTitle + '温控器'"
    (nzOnCancel)="handleOnCloseModal()"
    [nzFooter]="modalFooter"
  >
    <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOnUpdate()">
      <nz-form-item>
        <h3>楼宇控制器基础设置</h3>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzRequired nzFor="premisesId">楼宇</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="premisesId" class="middle-width">
            <nz-option
              *ngFor="let item of premises"
              [nzValue]="item.premisesId"
              [nzLabel]="item.premisesName"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzRequired nzFor="floorId">楼层</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="floorId" class="middle-width">
            <nz-option
              *ngFor="let item of floors"
              [nzValue]="item.floorId"
              [nzLabel]="item.floorName"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzRequired nzFor="terminalId">温控终端ID</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="terminalId">
            <nz-option
              *ngFor="let item of terminalIds"
              [nzValue]="item"
              [nzLabel]="item"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzRequired nzFor="terminalPosition">温控终端名称（位置）</nz-form-label>
        <nz-form-control>
          <input nz-input formControlName="terminalPosition" />
        </nz-form-control>
      </nz-form-item>
      <div formGroupName="modeGroup">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="modeId">温控模式ID</nz-form-label>
          <nz-form-control>
            <nz-select formControlName="modeName" nzAllowClear>
              <nz-option
                *ngFor="let item of modes"
                [nzValue]="item.modeName"
                [nzLabel]="item.modeName"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <div formGroupName="typeGroup" *ngIf="handleGetModeName() && typeGroupVisible">
          <nz-form-item *ngIf="handleGetModeName() === '类型段'">
            <nz-form-label nzRequired nzFor="periodTypeId">温控类型名称</nz-form-label>
            <nz-form-control nzFor="periodTypeId" nzRequired>
              <nz-select formControlName="periodTypeId">
                <nz-option
                  *ngFor="let item of types"
                  [nzValue]="item.typeId"
                  [nzLabel]="item.typeName"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item *ngIf="handleGetModeName() !== '类型段'">
            <nz-form-label nzRequired nzFor="temperatureDownlimit">温度上下限</nz-form-label>
            <nz-form-control>
              <nz-input-number formControlName="tempDownLogical"></nz-input-number>
            </nz-form-control>
            <span class="separator"> - </span>
            <nz-form-control>
              <nz-input-number formControlName="tempUpLogical"></nz-input-number>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <nz-divider></nz-divider>
      <nz-form-item>
        <h3>楼宇控制器基础设置</h3>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>温控终端持续加热时长（分钟）</nz-form-label>
        <nz-form-control>
          <input nz-input [value]="60" disabled />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>温控终端阻断加热时长（分钟）</nz-form-label>
        <nz-form-control>
          <input nz-input [value]="3" disabled />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>温控终端持续加热时长（分钟）</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSuffix="℃">
            <input nz-input [value]="18" nzSuffix="℃" disabled />
          </nz-input-group>
        </nz-form-control>
        <span class="separator"> / </span>
        <nz-form-control>
          <nz-input-group nzSuffix="℃">
            <input nz-input [value]="23" nzSuffix="℃" disabled />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </form>
    <ng-template #modalFooter>
      <button nz-button [disabled]="!this.validateForm.valid" (click)="handleOnUpdate()">
        确定
      </button>
      <button nz-button (click)="handleOnCloseModal()">
        取消
      </button>
    </ng-template>
  </nz-modal>
</div>
