import { DataHomeHeadModule } from '@components/data-home-head/data-home-head.module'
import { DataChartBoxModule } from '@components/data-chart-box/data-chart-box.module'
import { RegionalEnergyConsumptionModule } from '@components/regional-energy-consumption/regional-energy-consumption.module'
import { AreaStateModule } from '@components/area-state/area-state.module'
import { TemperatureChartModule } from '@components/temperature-chart/temperature-chart.module'
import { DataHomeStatisticModule } from '@components/data-home-statistics/data-home-statistic.module'
import { DataHomeMapModule } from '@components/data-home-map/data-home-map.module'
import { NgModule } from '@angular/core'
import { DatavWebglComponent } from '@app/pages/datav-webgl/datav-webgl.component'
import { UnityWebglComponent } from '@components/unity-webgl/unity-webgl.component'
import { UnityWebglModule } from '@components/unity-webgl/unity-webgl.module'

const modules = [
  DataHomeHeadModule,
  DataChartBoxModule,
  RegionalEnergyConsumptionModule,
  AreaStateModule,
  TemperatureChartModule,
  DataHomeStatisticModule,
  DataHomeMapModule,
  UnityWebglModule,
]

@NgModule({
  imports: [...modules],
  exports: [],
  declarations: [DatavWebglComponent],
})
export class DatavWebglModule {}
