import { NgModule } from '@angular/core'
import { TaiyuanCollegeOperatingComponent } from '@components/taiyuan-college-operating/taiyuan-college-operating.component'
import { NzTableModule } from 'ng-zorro-antd/table'
import { CommonModule } from '@angular/common'
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm'
import {
  NzButtonModule,
  NzDrawerModule,
  NzFormModule,
  NzInputModule,
  NzListModule,
  NzModalModule,
  NzPaginationModule,
  NzSelectModule,
  NzToolTipModule,
  NzTypographyModule,
} from 'ng-zorro-antd'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { TaiyuanCollegeLeafletComponent } from '@components/taiyuan-college-leaflet/taiyuan-college-leaflet.component'
import { TaiyuanCollegeLeafletModule } from '@components/taiyuan-college-leaflet/taiyuan-college-leaflet.module'
import { PipeModule } from '@app/pipes/pipe-module.modules'

@NgModule({
  imports: [
    NzTableModule,
    CommonModule,
    NzPopconfirmModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    ReactiveFormsModule,
    NzSelectModule,
    FormsModule,
    NzModalModule,
    NzPaginationModule,
    NzDrawerModule,
    NzListModule,
    NzTypographyModule,
    NzToolTipModule,
    TaiyuanCollegeLeafletModule,
    PipeModule,
  ],
  exports: [TaiyuanCollegeOperatingComponent],
  declarations: [TaiyuanCollegeOperatingComponent],
})
export class TaiyuanCollegeOperatingModule {}
