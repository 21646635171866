import { HttpParams } from '@angular/common/http'
import moment from 'moment'
const convertTemperature = data => {
  const temperature = Number.parseFloat(data)
  return isNaN(temperature) ? '' : `${temperature}℃`
}

const convertRangeTemperature = (up, down) => {
  return convertTemperature(up) + ' / ' + convertTemperature(down)
}

const convertRangeTime = (before, after) => {
  return convertTime(before) + ' - ' + convertTime(after)
}

const convertTime = (data, format = 'YYYY-MM--DD HH:mm:ss') => {
  return validateTime(data) ? moment(data).format(format) : ''
}

const generateHttpPrams = (data, ignore: [string] = ['']) => {
  let params = new HttpParams()
  for (const item in data) {
    if (ignore.includes(item)) {
      continue
    }
    params = params.set(item, data[item])
  }
  return params
}

const copyObject = (data, ignore: [string] = ['']) => {
  let params = {}
  for (const item in data) {
    if (ignore.includes(item)) {
      continue
    }
    params[item] = data[item]
  }
  return params
}

const validateTime = time => {
  if (new Date(time).toString() == 'Invalid Date') {
    return false
  }
  return true
}

export {
  convertTemperature,
  convertRangeTemperature,
  generateHttpPrams,
  copyObject,
  convertRangeTime,
  convertTime,
}
