import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class SideFrameService {
  constructor(private activeRouter: ActivatedRoute) {}

  delete_content(url: string) {
    console.log('side frame url: ', url)
  }
}
