import { NgModule } from '@angular/core'
import { TaiyuanCollegeTerminalComponent } from '@components/taiyuan-college-terminal/taiyuan-college-terminal.component'
import {
  NzButtonModule,
  NzDividerModule,
  NzFormModule,
  NzInputModule,
  NzInputNumberModule,
  NzModalModule,
  NzPopconfirmModule,
  NzSelectModule,
  NzSwitchModule,
  NzTableModule,
} from 'ng-zorro-antd'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { TemperaturePipe } from '@app/pipes/temperature.pipe'
import { PipeModule } from '@app/pipes/pipe-module.modules'

@NgModule({
  declarations: [TaiyuanCollegeTerminalComponent],
  exports: [TaiyuanCollegeTerminalComponent],
  imports: [
    NzTableModule,
    NzSelectModule,
    FormsModule,
    CommonModule,
    NzButtonModule,
    NzPopconfirmModule,
    NzModalModule,
    ReactiveFormsModule,
    NzFormModule,
    NzInputNumberModule,
    NzSwitchModule,
    NzInputModule,
    NzDividerModule,
    PipeModule,
  ],
})
export class TaiyuanCollegeTerminalModule {}
