import { NgModule } from '@angular/core'
import { TaiyuanTypeTimeSettingComponent } from '@components/taiyuan-type-time-setting/taiyuan-type-time-setting.component'
import { CommonModule } from '@angular/common'
import {
  NzButtonModule,
  NzFormModule,
  NzInputModule,
  NzInputNumberModule,
  NzModalModule,
  NzPopconfirmModule,
  NzSelectModule,
  NzSwitchModule,
  NzTableModule,
  NzTimePickerModule,
} from 'ng-zorro-antd'
import { NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { PipeModule } from '@app/pipes/pipe-module.modules'

@NgModule({
  declarations: [TaiyuanTypeTimeSettingComponent],
  exports: [TaiyuanTypeTimeSettingComponent],
  imports: [
    CommonModule,
    NzTableModule,
    NzButtonModule,
    NzPopconfirmModule,
    NgbButtonsModule,
    NzSelectModule,
    FormsModule,
    NzModalModule,
    ReactiveFormsModule,
    NzInputNumberModule,
    NzFormModule,
    NzTimePickerModule,
    NzSwitchModule,
    NzInputModule,
    PipeModule,
  ],
})
export class TaiyuanTypeTimeSettingModule {}
