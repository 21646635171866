import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { AliCloudResponse, appCode, prefix_url } from '@app/common/AliCloudResponse'

@Injectable({
  providedIn: 'root',
})
export class AreaStateService {
  constructor(private http: HttpClient) {}
  items_colors = [
    [
      '#1d4c26',
      '#3fb95b',
      '#576b5a',
      '#8cb594',
      '#508e75',
      '#babf61',
      '#5f6839',
      '#b3cb51',
      '#8b9c7c',
      '#503c3e',
    ],
    [
      '#13a9bd',
      '#1ce1e5',
      '#0e6666',
      '#93b7ba',
      '#374e5e',
      '#618ea5',
      '#9eb3ce',
      '#b0a1be',
      '#899e95',
      '#e0be90',
    ],
    [
      '#f05f44',
      '#edb9b2',
      '#8e6d68',
      '#c76331',
      '#cc8239',
      '#d9b267',
      '#efd8b6',
      '#c15950',
      '#d9a43c',
      '#9a5d3e',
    ],
    [
      '#ffffff',
      '#adadad',
      '#5b5b5b',
      '#333333',
      '#2e3238',
      '#3b4850',
      '#81908b',
      '#dadfd9',
      '#827d77',
      '#969a99',
    ],
  ]

  generatorResult = data => {
    return {
      name: data.project,
      item: [
        {
          name: '供热面积',
          data: {
            val: data.area_heating_now,
            total: data.area_heating,
          },
          item: [
            {
              name: '加热中面积',
              val: data.area_heatinng,
            },
            {
              name: '闲置面积',
              val: data.area_idle,
            },
            {
              name: '故障面积',
              val: data.area_fault,
            },
          ],
        },
        {
          name: '总设备数量',
          data: {
            val: data.device_normally,
            total: data.device,
          },
          item: [
            {
              name: '加热中设备数量',
              val: data.device_heating,
            },
            {
              name: '闲置中设备数量',
              val: data.device_idle,
            },
            {
              name: '故障设备数量',
              val: data.device_fault,
            },
          ],
        },
        {
          name: '总设备配比',
          data: {
            val: data.device_normally_proportion,
            total: data.device_proportion,
          },
          item: [
            {
              name: '加热设备配比',
              val: data.device_proportion_heating,
            },
            {
              name: '闲置设备配比',
              val: data.device_proportion_idle,
            },
            {
              name: '故障设备配比',
              val: data.device_proportion_fault,
            },
          ],
        },
        {
          name: '能耗配比',
          data: {
            val: data.consumption_proportion_now,
            total: data.consumption_proportion,
          },
          item: [
            {
              name: '加热状态能耗占比',
              val: data.consumption_proportion_heating,
            },
            {
              name: '闲置状态能耗占比',
              val: data.consumption_proportion_idle,
            },
            {
              name: '故障状态能耗占比',
              val: data.consumption_proportion_fault,
            },
          ],
        },
      ],
      indicators: {
        left: [
          {
            name: '总面积(m²)',
            val: data.project_area,
          },
          {
            name: '单位能耗(w/m²)',
            val: data.project_consumption_unit,
          },
        ],
        right: [
          {
            name: '室外温度(℃)',
            val: data.project_out_weather,
          },
          {
            name: '平均室温(℃)',
            val: data.project_in_weather,
          },
        ],
      },
      temperature: null,
    }
  }

  generatorTemperature = (data, temperature) => {
    const tcr = {
      date: [],
      external: [],
      internal: [],
    }
    temperature.filter(item => {
      tcr.date.push(item.date)
      tcr.internal.push(item.internal_temp)
      tcr.external.push(item.external_temp)
    })
    data.temperature = tcr
  }

  async getServiceData(projectName) {
    const params = new HttpParams().set('appCode', appCode).set('project', projectName)
    let result = null
    const url = `${prefix_url}/heating/bgsc/01/`
    const getData = this.http
      .get<AliCloudResponse>(url, { params })
      .toPromise()
    const getTemperature = this.http
      .get<AliCloudResponse>(`${prefix_url}/heating/bgsc/02/`, { params })
      .toPromise()
    await Promise.all([getData, getTemperature]).then(allRes => {
      const data = allRes[0].data[0]
      const temperature = allRes[1].data
      result = this.generatorResult(data)
      this.generatorTemperature(result, temperature)
    })
    return result
  }

  getMouseMoveChartOptions(data): object {
    const default_color = 'rgba(3, 31, 56, .8)'
    const items = data.item
    const colors = ['#287f4e', '#13a9bd', '#f05f44', '#fff']
    const series = items.map((item, index) => {
      const pie_begin = (index * 2 + 1) * 10
      return {
        name: item.name,
        // startAngle: (Math.random() * 360).toFixed(0),
        type: 'pie',
        radius: [`${pie_begin}%`, `${pie_begin + 5}%`],
        label: {
          show: false,
        },
        data: [
          {
            label: {
              show: false,
            },
            tooltip: {},
            labelLine: {
              show: false,
              showAbove: index % 2 === 1 ? true : false,
              lineStyle: {
                color: colors[index],
              },
            },
            value: item.data.val,
            name: item.name,
            itemStyle: {
              color: colors[index],
            },
          },
          {
            value: item.data.total - item.data.val,
            name: item.name + index,
            tooltip: {
              show: false,
            },
            itemStyle: {
              color: default_color,
            },
            labelLine: {
              show: false,
            },
            label: {
              show: false,
            },
          },
        ],
        animationType: 'expansion',
        animation: true,
        emphasis: {
          scale: false,
        },
      }
    })
    return {
      tooltip: {},
      legend: {
        show: false,
      },
      series: series,
    }
  }

  getChartOption(xaxis, external, internal): object {
    return {
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        icon: 'rect',
        data: ['室外温度(℃)', '室内温度(℃)'],
        itemGap: 44,
        textStyle: {
          color: '#ffffff',
          fontSize: 16,
        },
      },
      xAxis: {
        show: false,
        axisLabel: {
          interval: 0,
          textStyle: {
            color: '#13a9bd',
            fontStyle: 'normal',
            fontFamily: '微软雅黑',
            fontSize: 19,
          },
        },
        axisTick: {
          show: true,
          lineStyle: {
            color: '#808c96',
          },
        },
        axisLine: {
          lineStyle: {
            color: '#808c96',
          },
        },
        type: 'category',
        boundaryGap: false,
        data: xaxis,
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false,
        },
        axisLabel: {
          margin: 15,
          color: '#13a9bd',
          fontSize: 20,
        },
        axisTick: {
          show: false,
          lineStyle: {
            color: '#ffffff',
          },
        },
      },
      series: [
        {
          name: '室外温度(℃)',
          data: external,
          type: 'line',
          color: '#13a9bd',
        },
        {
          name: '室内温度(℃)',
          data: internal,
          type: 'line',
          color: '#d47336',
        },
      ],
    }
  }

  getOptions(data, index): object {
    const items = data.item[index]
    const data_source = items.item
    const colors = this.items_colors[index]
    const information = data_source.map((item, index) => {
      return {
        value: item.val,
        name: `${item.name}`,
        itemStyle: {
          color: colors[index % 10],
        },
      }
    })
    return {
      tooltip: {},
      legend: {
        show: true,
        bottom: 0,
        textStyle: {
          color: '#fff',
        },
      },
      series: [
        {
          center: ['50%', '45%'],
          radius: [0, '70%'],
          name: items.name,
          type: 'pie',
          label: {
            show: true,
          },
          data: information,
          animation: false,
          emphasis: {
            scale: false,
          },
        },
      ],
    }
  }
}
