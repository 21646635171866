import { NgModule } from '@angular/core'
import { LayoutModule } from '../components/ui/layout/layout.module'

import { LayoutAuthComponent } from './Auth/auth.component'
import { LayoutMainComponent } from './Main/main.component'
import { LayoutPublicComponent } from './Public/public.component'
import { NzDrawerModule, NzLayoutModule } from 'ng-zorro-antd'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

const COMPONENTS = [LayoutAuthComponent, LayoutMainComponent, LayoutPublicComponent]

@NgModule({
  imports: [LayoutModule, NzLayoutModule, NzDrawerModule, CommonModule, RouterModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class LayoutsModule {}
