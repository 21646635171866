import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NgxEchartsModule } from 'ngx-echarts'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { TaiyuanCollegeBuildModule } from '@components/taiyuan-college-build/taiyuan-college-build.module'
import { TaiyuanCollegeOperatingModule } from '@components/taiyuan-college-operating/taiyuan-college-operating.module'
import { TaiyuanCollegeSystemModule } from '@components/taiyuan-college-system/taiyuan-college-system.module'
import { TaiyuanTypeTimeSettingModule } from '@components/taiyuan-type-time-setting/taiyuan-type-time-setting.module'
import { TaiyuanCollegeFloorModule } from '@components/taiyuan-college-floor/taiyuan-college-floor.module'
import { TaiyuanCollegeTerminalModule } from '@components/taiyuan-college-terminal/taiyuan-college-terminal.module'
import { TaiyuanCollegeModeModule } from '@components/taiyuan-college-mode/taiyuan-college-mode.module'
import { SideFrameModule } from '@components/side-frame/side-frame.module'
import { TaiyuanCollegeHeapOperationModule } from '@components/taiyuan-college-heap-operation/taiyuan-college-heap-operation.module'
import { TaiyuanCollegeSupervisionModule } from '@components/taiyuan-college-supervision/taiyuan-college-supervision.module'

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxEchartsModule,
    NzToolTipModule,
    TaiyuanCollegeBuildModule,
    TaiyuanCollegeOperatingModule,
    TaiyuanCollegeSystemModule,
    TaiyuanTypeTimeSettingModule,
    TaiyuanCollegeFloorModule,
    TaiyuanCollegeTerminalModule,
    TaiyuanCollegeModeModule,
    TaiyuanCollegeHeapOperationModule,
    TaiyuanCollegeSupervisionModule,
    SideFrameModule,
  ],
  exports: [],
})
export class ComponentsModule {}
