import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { AliCloudResponse, appCode, prefix_url } from '@app/common/AliCloudResponse'
import { COLLEGE_OPERATING, TEMPERATURE_MODE, TEMPERATURE_TYPE } from '@app/common/TaidaApi'
import { FormGroup } from '@angular/forms'

@Injectable({
  providedIn: 'root',
})
export class TaiyuanCollegeOperatingService {
  constructor(private http: HttpClient) {}

  getColumns() {
    return [
      {
        key: 'sid',
        title: '查询ID',
        width: '100px',
        ellipsis: true,
      },
      {
        key: 'premisesName',
        title: '楼宇',
        width: '150px',
      },
      {
        key: 'floorName',
        title: '楼层',
      },
      {
        key: 'terminalPosition',
        title: '终端位置',
      },
      {
        key: 'modeName',
        title: '供热模式',
      },
      {
        key: 'periodType',
        title: '供热类型',
      },
      {
        key: 'defaultPeriod',
        title: '类型当前时段',
        width: '120px',
      },
      {
        key: 'temperatureRange',
        title: '温度区间',
        width: '120px',
      },
      {
        key: 'runningType',
        title: '运行状态',
      },
      {
        key: 'prevTime',
        title: '上次信息时间',
        width: '120px',
      },
      {
        key: 'temperature',
        title: '实际温度',
      },
      {
        key: 'status',
        title: '连接状态',
      },
    ]
  }

  getSort() {
    return {
      energy: true,
      temperature: true,
    }
  }

  handleOnSearch(searchModel, pagination) {
    const model = searchModel.value
    for (let k in model) {
      if (!model[k]) {
        model[k] = ''
      }
    }
    const params = Object.assign(model, pagination, { appCode })
    const url = `${prefix_url}${COLLEGE_OPERATING}`
    return this.http
      .get<AliCloudResponse>(url, { params })
      .toPromise()
  }

  handleOnReset(searchModel) {
    for (let item in searchModel) {
      searchModel[item].reset()
    }
  }

  handleGetTemperatureType(params) {
    const param = Object.assign(params, { appCode })
    const url = `${prefix_url}${TEMPERATURE_TYPE}`
    return this.http
      .get<AliCloudResponse>(url, { params: param })
      .toPromise()
  }
  handleGetTemperatureMode(params) {
    const param = Object.assign(params, { appCode })
    console.log('mode params: ', param)
    const url = `${prefix_url}${TEMPERATURE_MODE}`
    return this.http
      .get<AliCloudResponse>(url, { params: param })
      .toPromise()
  }

  handleGetLeafLetData(search: any) {
    const param = Object.assign({}, { appCode: '3cbf3469059b491a91c7f2a43889d381' }, search)
    const url =
      'http://c3e35869d12944cbba6b8ba19f5b2182-cn-beijing.alicloudapi.com/rhthermo/eheating/tyxy/operating/v1/leaflet'
    return this.http
      .get<AliCloudResponse>(url, { params: param })
      .toPromise()
  }
}
