import { Inject, Injectable } from '@angular/core'
export interface Params {
  databaseIp: string
  localIp: string
  localPort: number
  comPort: string
  comFormat: string
  mqttIp: string
  mqttPort: string
  timeSyncInterval: number
  collectDataInterval: number
  lockedInterval: number
  heatDuration: number
  blockingHeatDuration: number
  terminalExecutionAsync: number
  collectDataAsync: number
}
@Injectable({
  providedIn: 'root',
})
export class TaiyuanCollegeSystemService {
  getBaseParams() {
    return {
      databaseIp: '127.0.0.1',
      localIp: '192.168.3.109',
      localPort: 10005,
      comPort: 'COM1',
      comFormat: '2400,n,8,1',
      mqttIp: '47.92.106.108',
      mqttPort: '1883',
    }
  }
  getAsyncParams() {
    return {
      terminalExecutionAsync: 60,
      collectDataAsync: 60,
    }
  }
  getSystemParams() {
    return {
      timeSyncInterval: 60,
      collectDataInterval: 1,
      lockedInterval: 45,
      heatDuration: 60,
      blockingHeatDuration: 3,
    }
  }

  getPremised() {
    return [
      {
        premisesId: 1,
        premisesName: '1号楼',
      },
      {
        premisesId: 2,
        premisesName: '2号楼',
      },
    ]
  }
  getFloors(id: number) {
    return [
      {
        floorId: 1,
        floorName: '1层楼',
      },
      {
        floorId: 2,
        floorName: '2层楼',
      },
    ]
  }
  getTerminal(id: number) {
    return [
      {
        terminalPositionId: 1,
        terminalPosition: '101室',
        deviceId: 1,
      },
    ]
  }
}
