import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl } from '@angular/forms'
import { CommonService } from '@app/common/CommonService'
import { TaiyuanCollegeHeapOperationService } from '@components/taiyuan-college-heap-operation/taiyuan-college-heap-operation.service'

@Component({
  selector: 'app-taiyuan-college-heap-operation',
  templateUrl: './taiyuan-college-heap-operation.component.html',
  styleUrls: ['./taiyuan-college-heap-operation.component.scss'],
})
export class TaiyuanCollegeHeapOperationComponent implements OnInit {
  columns = []
  data = []
  search = this.fb.group({
    sid: new FormControl(''),
    premisesId: new FormControl(''),
    floorId: new FormControl(''),
    modeName: new FormControl(''),
    periodType: new FormControl(''),
  })
  pagination = {
    pageNum: 1,
    pageSize: 10,
  }
  deviceVisible = false
  visibleStatus = false
  records = []
  sort = {}
  sortContainer = []
  totalCount = 0
  premises = []
  floors = []
  defaultPeriod = []
  temperatureType = []
  mapVisible = false
  leafLetData = []

  constructor(
    private fb: FormBuilder,
    private service: TaiyuanCollegeHeapOperationService,
    private commonService: CommonService,
  ) {}

  async ngOnInit() {
    this.columns = this.service.getColumns()
    this.handleOnSearch()
    this.commonService.handleGetPremises().then(res => {
      this.premises = res.data.rows
    })
  }

  handleOnSearch() {
    this.handleOnClearSort()
    this.service.handleOnSearch(this.search, this.pagination).then(res => {
      const data = res.data
      this.data = data.rows
      this.totalCount = data.totalNumber
    })
  }
  handleOnReset() {
    this.service.handleOnReset(this.search)
  }
  handleOnPageIndexChange(item: number) {
    this.pagination.pageNum = item
    this.handleOnSearch()
  }
  handleOnPageSizeChange(item: number) {
    this.pagination.pageSize = item
    this.handleOnSearch()
  }
  handleOnGetRecord(item) {
    this.records = JSON.parse(item.operatingData)
    this.visibleStatus = true
  }
  handleOnSortChange(event) {
    let index = this.sortContainer.indexOf(event.key)
    console.log('column: ', this.columns)
    if (event.value) {
    }
  }
  handleOnClearSort() {
    this.columns.forEach(item => {
      item.sortOrder = null
    })
  }

  handleOnSearchPremiseSelectChange($event) {
    this.search.controls['floorId'].setValue('')
    this.search.controls['periodType'].setValue('')
    if (!$event) {
      this.floors = []
      this.defaultPeriod = []
    } else {
      this.handleGetFloors($event)
    }
  }

  handleOnSearchFloorSelectChange($event) {
    this.search.controls['periodType'].setValue('')
    if (!$event) {
      this.defaultPeriod = []
    }
  }

  handleGetFloors(premisesId: number) {
    this.search.controls['floorId'].setValue('')
    this.commonService.handleGetFloors(premisesId).then(res => {
      this.floors = res.data
    })
  }
}
