import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { UnifyResponse } from '@app/common/AliCloudResponse'
import { MODE_LIST, MODE_MODIFY } from '@app/common/TaidaApi'

@Injectable({
  providedIn: 'root',
})
export class TaiyuanCollegeModeService {
  constructor(private http: HttpClient) {}

  getColumns() {
    return [
      {
        title: '模式ID',
        key: 'modeId',
      },
      {
        title: '模式名称',
        key: 'modeName',
      },
      {
        title: '模式温度上下限',
        key: 'temperature',
      },
    ]
  }

  handleGetData(value) {
    const { premisesId, floorId } = value
    return this.http
      .get<Array<Object>>(MODE_LIST, { params: { pid: premisesId, fid: floorId } })
      .toPromise()
  }

  handleModeModify(value: object) {
    return this.http.post(MODE_MODIFY, value).toPromise()
  }
}
