import { NgModule } from '@angular/core'
import { TaiyuanCollegeFloorComponent } from '@components/taiyuan-college-floor/taiyuan-college-floor.component'
import {
  NzButtonModule,
  NzFormModule,
  NzInputModule,
  NzInputNumberModule,
  NzModalModule,
  NzPopconfirmModule,
  NzSelectModule,
  NzSwitchModule,
  NzTableModule,
} from 'ng-zorro-antd'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

@NgModule({
  declarations: [TaiyuanCollegeFloorComponent],
  exports: [TaiyuanCollegeFloorComponent],
  imports: [
    NzTableModule,
    NzButtonModule,
    NzPopconfirmModule,
    CommonModule,
    NzSelectModule,
    FormsModule,
    NzModalModule,
    NzFormModule,
    ReactiveFormsModule,
    NzInputNumberModule,
    NzInputModule,
    NzSwitchModule,
  ],
})
export class TaiyuanCollegeFloorModule {}
