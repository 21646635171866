import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'temperature',
})
export class TemperaturePipe implements PipeTransform {
  transform(value: string, ...args: string[]): unknown {
    const flag = isNaN(Number.parseFloat(value))
    if (flag && args.length !== 0) {
      return '/'
    } else if (flag && args.length === 0) {
      return ''
    }
    const symbol = '℃'
    const temperatureDown = `${Number.parseFloat(value)}${symbol}`
    if (args.length === 0) {
      return temperatureDown
    }
    const temperatureUp = isNaN(Number.parseFloat(args[0]))
      ? ''
      : `${Number.parseFloat(args[0])}${symbol}`
    const separator = args[1] ? args[1] : ' / '
    return `${temperatureDown} ${separator} ${temperatureUp}`
  }
}
