<div class="container-wrapper">
  <div class="bg-container"></div>
  <div class="content-wrapper">
    <div class="search-wrapper">
      <div class="form-item">
        <span>接入模式：</span>
        <nz-select [(ngModel)]="search.mode" nzAllowClear>
          <nz-option
            *ngFor="let item of moods"
            [nzValue]="item.name"
            [nzLabel]="item.name"
          ></nz-option>
        </nz-select>
      </div>
      <div class="operate">
        <nz-button-group>
          <button nz-button class="rhthermo-button-hover" (click)="handleOnSearch()">查询</button>
          <button class="rhthermo-button-hover" nz-button (click)="handleOnOpen()">添加</button>
        </nz-button-group>
      </div>
    </div>
    <div class="table-wrapper">
      <nz-table #basicTable [nzData]="data" [nzFrontPagination]="false">
        <thead>
          <tr>
            <th *ngFor="let column of columns" [nzWidth]="column.width" nzAlign="center">
              <span>{{ column.title }}</span>
            </th>
            <th class="pointer" nzAlign="center" style="color: #D4380D !important;">连接测试</th>
            <th class="pointer" nzAlign="center">修改属性</th>
            <th class="pointer" nzAlign="center">楼宇启停</th>
            <th class="pointer" nzAlign="center">删除关联设备</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td nzAlign="center">{{ data.premisesId }}</td>
            <td nzAlign="center">{{ data.premisesName }}</td>
            <td nzAlign="center">{{ data.accessMode }}</td>
            <td nzAlign="center">{{ data.ipAddress }}</td>
            <td nzAlign="center">{{ data.groupCount }}</td>
            <td class="pointer" nzAlign="center">
              <button nz-button class="rhthermo-table-text-button">
                测试
              </button>
            </td>
            <td class="pointer" nzAlign="center">
              <button nz-button (click)="handleOnOpen(data)" class="rhthermo-table-text-button">
                修改
              </button>
            </td>
            <td nzAlign="center">
              <nz-switch
                class="rhthermo-switch"
                [(ngModel)]="data.disabled"
                nzCheckedChildren="0"
                nzUnCheckedChildren="1"
                nzCheckedChildren="开"
                nzUnCheckedChildren="关"
                nzControl="true"
                (click)="handleOnSwitch(data)"
              ></nz-switch>
            </td>
            <td class="pointer" nzAlign="center">
              <button
                nz-button
                nz-popconfirm
                nzPopconfirmTitle="确定要删除吗?"
                (nzOnConfirm)="handleOnDeleteTerminal(data)"
                class="rhthermo-table-text-button"
              >
                删除
              </button>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
  <nz-modal
    [(nzVisible)]="modalVisible"
    [nzTitle]="title + '楼宇'"
    (nzOnCancel)="handleOnCancel()"
    (nzOnOk)="handleOnUpdate()"
    nzClassName="modal-wrapper"
  >
    <ng-container>
      <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOnUpdate()">
        <nz-form-item>
          <h3>楼宇控制器基础设置</h3>
        </nz-form-item>
        <!--        <nz-form-item *ngIf="isUpdate">-->
        <!--          <nz-form-label nzRequired nzFor="premisesId">楼宇ID</nz-form-label>-->
        <!--          <nz-form-control nzErrorTip="楼宇ID不能为空">-->
        <!--            <nz-input-number-->
        <!--              nzPlaceHolder="楼宇ID"-->
        <!--              nz-input-->
        <!--              nzMin="1"-->
        <!--              [nzMax]="maxValue"-->
        <!--              formControlName="premisesId"-->
        <!--              id="premisesId"-->
        <!--            ></nz-input-number>-->
        <!--          </nz-form-control>-->
        <!--        </nz-form-item>-->
        <nz-form-item>
          <nz-form-label nzRequired nzFor="premisesName">楼宇名称（位置）</nz-form-label>
          <nz-form-control nzErrorTip="楼宇名称不能为空">
            <input nz-input formControlName="premisesName" id="premisesName" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired nzFor="accessMode">接入模式</nz-form-label>
          <nz-form-control nzErrorTip="请选择模式">
            <nz-select nzPlaceHolder="请选择模式" formControlName="accessMode" nzAllowClear>
              <nz-option
                *ngFor="let item of moods"
                [nzValue]="item.name"
                [nzLabel]="item.name"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired nzFor="ipAddress">IP地址/中继ID</nz-form-label>
          <nz-form-control nzErrorTip="ip地址错误或未填">
            <input nz-input formControlName="ipAddress" id="ipAddress" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired nzFor="groupCount">楼层数量</nz-form-label>
          <nz-form-control nzErrorTip="请输入分组数量">
            <nz-input-number
              nzPlaceHolder="分组数量"
              nz-input
              nzMin="1"
              formControlName="groupCount"
              id="groupCount"
            ></nz-input-number>
          </nz-form-control>
        </nz-form-item>

        <nz-divider></nz-divider>

        <nz-form-item>
          <h3>楼宇控制器硬件设置</h3>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzFor="timeSyncInterval">校时间隔</nz-form-label>
          <nz-form-control class="constant-style">
            <input nz-input formControlName="timeSyncInterval" id="timeSyncInterval" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzFor="collectDataInterval">采集数据间隔（分钟）</nz-form-label>
          <nz-form-control class="constant-style">
            <input nz-input formControlName="collectDataInterval" id="collectDataInterval" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzFor="lockedInterval">锁机间隔（天）</nz-form-label>
          <nz-form-control class="constant-style">
            <input nz-input formControlName="lockedInterval" id="lockedInterval" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzFor="terminalExecutionAsync">终端执行MOD</nz-form-label>
          <nz-form-control class="constant-style">
            <input nz-input formControlName="terminalExecutionAsync" id="terminalExecutionAsync" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzFor="collectDataAsync">异步采样MOD</nz-form-label>
          <nz-form-control class="constant-style">
            <input nz-input formControlName="collectDataAsync" id="collectDataAsync" />
          </nz-form-control>
        </nz-form-item>
      </form>
    </ng-container>
  </nz-modal>
</div>
