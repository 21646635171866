import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { AliCloudResponse, appCode, prefix_url } from '@app/common/AliCloudResponse'

@Injectable({
  providedIn: 'root',
})
export class RegionalEnergyConsumptionService {
  constructor(private http: HttpClient) {}

  getData(): Observable<any> {
    let result = null
    const params = new HttpParams().set('appCode', appCode).set('id', '1')
    return this.http.get<AliCloudResponse>(`${prefix_url}/heating/bgsc/04/`, {
      params: params,
    })
  }
}
