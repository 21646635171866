import { NgModule } from '@angular/core'
import { DataChartBoxModule } from '@components/data-chart-box/data-chart-box.module'
import { TemperatureChartComponent } from '@components/temperature-chart/temperature-chart.component'
import { NgxEchartsModule } from 'ngx-echarts'

@NgModule({
  imports: [DataChartBoxModule, NgxEchartsModule],
  exports: [TemperatureChartComponent],
  declarations: [TemperatureChartComponent],
})
export class TemperatureChartModule {}
