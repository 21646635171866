import { FormGroup } from '@angular/forms'

const dateFormat = (fmt, date) => {
  let ret
  let opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
  }
  for (let k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
    }
  }
  return fmt
}

const formGroupConvertObject = (group: FormGroup, source: Object) => {
  const obj = {}
  for (const item in source) {
    console.log('item: ', item, ' -> ', source[item])
  }
  return obj
}

export { dateFormat, formGroupConvertObject }
