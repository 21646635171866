<div class="container-wrapper">
  <div class="bg-container"></div>
  <div class="content-wrapper">
    <div class="search-wrapper">
      <form nz-form [nzLayout]="'inline'" [formGroup]="search">
        <nz-form-item>
          <nz-form-label nzFor="premisesId">楼宇</nz-form-label>
          <nz-form-control>
            <nz-select
              formControlName="premisesId"
              class="middle-width"
              id="premisesId"
              (ngModelChange)="handleOnSearchPremiseSelectChange($event)"
            >
              <nz-option
                *ngFor="let item of premises"
                [nzValue]="item.premisesId"
                [nzLabel]="item.premisesName"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzFor="floorId">楼层</nz-form-label>
          <nz-form-control>
            <nz-select formControlName="floorId" class="middle-width" id="floorId">
              <nz-option
                *ngFor="let item of floors"
                [nzValue]="item.floorId"
                [nzLabel]="item.floorName"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control class="operate">
            <button class="rhthermo-button-hover" nz-button (click)="handleGetData()">
              查询
            </button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
    <div class="table-wrapper">
      <nz-table #baseTable [nzData]="data" [nzFrontPagination]="false" nzTableLayout="fixed">
        <thead>
          <tr>
            <th *ngFor="let column of columns" [nzWidth]="column.width" nzAlign="center">
              <span>{{ column.title }}</span>
            </th>
            <th nzAlign="center">修改</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of baseTable.data">
            <td nzAlign="center">{{ data.modeId }}</td>
            <td nzAlign="center">{{ data.modeName }}</td>
            <td nzAlign="center">
              {{ data.tempDownlimits | temperature: data.tempUplimits }}
            </td>
            <td nzAlign="center">
              <button
                nz-button
                nzType="text"
                class="rhthermo-table-text-button"
                *ngIf="data.modeName === '防冻' || data.modeName === '维护'"
                (click)="handleOnOpen(data)"
              >
                修改
              </button>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
  <nz-modal
    [(nzVisible)]="modalVisible"
    nzTitle="修改模式信息"
    nzClassName="modal-wrapper"
    (nzOnCancel)="handleCloseModal()"
    (nzOnOk)="handleOnUpdate()"
  >
    <ng-container>
      <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOnUpdate()">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="premisesId">楼宇</nz-form-label>
          <nz-form-control>
            <nz-select formControlName="premisesId" class="middle-width">
              <nz-option
                *ngFor="let item of premises"
                [nzValue]="item.premisesId"
                [nzLabel]="item.premisesName"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired nzFor="floorId">楼层</nz-form-label>
          <nz-form-control>
            <nz-select formControlName="floorId" class="middle-width">
              <nz-option
                *ngFor="let item of floors"
                [nzValue]="item.floorId"
                [nzLabel]="item.floorName"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired nzFor="modeId">模式ID</nz-form-label>
          <nz-form-control>
            <input formControlName="modeId" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzRequired nzFor="modeName">模式名称</nz-form-label>
          <nz-form-control nzErrorTip="模式名称不能为空">
            <input formControlName="modeName" />
          </nz-form-control> </nz-form-item
        ><nz-form-item>
          <nz-form-label nzRequired nzFor="temperature">温度上下限</nz-form-label>
          <nz-form-control nzErrorTip="温度不能为空且不能低于1度高于100度">
            <input nz-input formControlName="tempDownlimits" />
          </nz-form-control>
          <nz-form-control nzErrorTip="温度不能为空且不能低于1度高于100度">
            <input nz-input formControlName="tempUplimits" />
          </nz-form-control>
        </nz-form-item>
      </form>
    </ng-container>
  </nz-modal>
</div>
