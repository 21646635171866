<div class="system-setting-wrapper">
  <div class="connection-params-wrapper">
    <form nz-form [formGroup]="systemForm">
      <nz-form-item>
        <h3>系统设置</h3>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="4" nzRequired nzFor="databaseIP">数据库IP</nz-form-label>
        <nz-form-control nzErrorTip="数据库IP不正确">
          <input nz-input placeHolder="数据库IP" formControlName="databaseIP" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="4" nzRequired nzFor="localIP">本机IP</nz-form-label>
        <nz-form-control nzErrorTip="本机IP不正确">
          <input nz-input placeHolder="本机IP" formControlName="localIP" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="4" nzRequired nzFor="localPort">本机端口</nz-form-label>
        <nz-form-control nzErrorTip="本机端口不正确">
          <nz-input-number
            nz-input
            nzPlaceHolder="本机端口"
            formControlName="localPort"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <h3>通信设置</h3>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="4" nzRequired nzFor="comPort">COM端口</nz-form-label>
        <nz-form-control nzErrorTip="COM端口">
          <nz-input-number
            nz-input
            nzPlaceHolder="COM端口"
            formControlName="comPort"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="4" nzRequired nzFor="comFormat">COM格式</nz-form-label>
        <nz-form-control nzErrorTip="COM格式">
          <input nz-input placeHolder="COM格式" formControlName="comFormat" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <h3>云端参数</h3>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="4" nzRequired nzFor="mqttIp">MQTT IP</nz-form-label>
        <nz-form-control nzErrorTip="MQTT IP不正确">
          <input nz-input placeHolder="MQTT IP" formControlName="mqttIP" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="4" nzRequired nzFor="mqttPort">MQTT 端口</nz-form-label>
        <nz-form-control nzErrorTip="MQTT 端口">
          <nz-input-number
            nz-input
            nzPlaceHolder="MQTT 端口"
            formControlName="mqttPort"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nzJustify="end">
        <button nz-button class="rhthermo-button-hover" (click)="handleOnSave()">保存</button>
        <button nz-button class="rhthermo-button-hover" (click)="handleOnCancel()">取消</button>
      </nz-form-item>
    </form>
  </div>
</div>
